import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { RiFileCopyFill } from "react-icons/ri";
function AddAccount() {
    const crose = "/assets/images/crose.svg"
    const AddUser = "/assets/images/addUser.svg"
    return (
        <div className="flex justify-center bg-mainColor w-full h-full p-4">
            <div className="border border-pure-greys-300 p-4 rounded-xl w-full max-w-6xl">
                {/* Create New ID Button */}
                <div className="flex justify-center mb-6">
                    <button className="rounded-3xl w-44 h-12 font-bold flex gap-x-2 items-center justify-center p-2 bg-buttonclr text-withdrawalbg">
                        <img src={AddUser} alt="Add User" />
                        <span>Create New ID</span>
                    </button>
                </div>

                <div className="flex flex-wrap justify-between  gap-2 lg:gap-0 mb-6">
                    {/* Left Column */}
                    <div className="w-full lg:w-[58%]">
                        {/* Add Account Section */}
                        <div className="w-full">
                            <div className="bg-topbg text-white rounded-t-md font-bold flex justify-center items-center p-2">
                                Add Account
                            </div>
                            <div className="bg-addbg p-6 rounded-b-lg">
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                    <div>
                                        <label htmlFor="accountType" className="block uppercase text-sm font-medium text-gray-700">
                                            Account Type:
                                        </label>
                                        <select
                                            id="accountType"
                                            className="mt-1 block w-full px-3 py-2 rounded-md shadow-sm focus:outline-none sm:text-sm"
                                        >
                                            <option value="">-- Select Account Type --</option>
                                            <option value="savings">Savings</option>
                                            <option value="current">Current</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label htmlFor="name" className="block uppercase text-sm font-medium text-gray-700">
                                            Name:
                                        </label>
                                        <input
                                            type="text"
                                            id="name"
                                            placeholder="Enter Name"
                                            className="mt-1 block w-full px-3 py-2 rounded-md shadow-sm focus:outline-none sm:text-sm"
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="accountNumber" className="block uppercase text-sm font-medium text-gray-700">
                                            A/C Number:
                                        </label>
                                        <input
                                            type="text"
                                            id="accountNumber"
                                            placeholder="Enter A/C Number"
                                            className="mt-1 block w-full px-3 py-2 rounded-md shadow-sm focus:outline-none sm:text-sm"
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="ifscCode" className="block uppercase text-sm font-medium text-gray-700">
                                            IFSC Code:
                                        </label>
                                        <input
                                            type="text"
                                            id="ifscCode"
                                            placeholder="Enter IFSC Code"
                                            className="mt-1 block w-full px-3 py-2 rounded-md shadow-sm focus:outline-none sm:text-sm"
                                        />
                                    </div>
                                </div>
                                <div className="flex justify-center items-center pt-10 gap-4">
                                    <button className="bg-green text-white px-6 py-2 rounded-md hover:bg-green focus:outline-none">
                                        Save
                                    </button>
                                    <button className="bg-redbg text-white px-6 py-2 rounded-md hover:bg-redbg focus:outline-none">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* Withdrawal Information */}
                        <div className="bg-white mt-6 p-6 rounded-md">
                            <ol className="list-decimal space-y-2 text-red font-poppins">
                                <li>The bank accounts for withdrawal must have the same name as the user registration name.</li>
                                <li>All withdrawals will be sent to the added bank account.</li>
                                <li>If you need to make any changes, you will need to contact our support team.</li>
                                <li>Withdrawal requests will be processed within 24 hours.</li>
                            </ol>
                        </div>

                        {/* Payment Issues Section */}
                        <div className="font-sans bg-withdrawalbg uppercase w-full mt-6 p-3 space-x-6 rounded-md flex justify-center items-center">
                            For payment-related issues, <span className="font-bold">click here.</span>
                            <FaWhatsapp size={28} />
                        </div>

                        {/* Account Details Section */}
                        <div className="w-full mt-6">
                            <div className="bg-topbg uppercase text-white rounded-t-md font-bold flex justify-center items-center p-2">
                                Raju Sharma
                            </div>
                            <div className="bg-addbg p-6 rounded-b-lg">
                                <div>
                                    <div className="space-y-2">
                                        <div className="flex gap-x-4">
                                            Bank Name: PUNJAB BANK <RiFileCopyFill size={24} />
                                        </div>
                                        <div className="flex gap-x-4">
                                            A/C No.: 643810030038975 <RiFileCopyFill size={24} />
                                        </div>
                                    </div>
                                    <select
                                        id="accountType"
                                        className="mt-4 block w-full px-3 py-2 rounded-md shadow-sm focus:outline-none sm:text-sm"
                                    >
                                        <option value="">-- Select Account Type --</option>
                                        <option value="savings">Savings</option>
                                        <option value="current">Current</option>
                                    </select>
                                </div>
                                <div className="flex justify-center items-center pt-6 gap-4">
                                    <button className="bg-redbg text-white px-6 py-2 rounded-md hover:bg-redbg focus:outline-none">
                                        Withdrawal
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Right Column */}
                    <div className="w-full lg:w-[40%]  "> 
                        <div className="w-full">
                            {/* Header Row */}
                            <div className="bg-topbg text-white rounded-t-md font-bold flex justify-between items-center p-2 text-sm ">
                                <div
                                    className="w-1/4 text-center bg-withdrawalbg text-black font-poppins border p-2 px-1 rounded-3xl truncate"
                                    title="Transaction No."
                                >
                                    Transaction No.
                                </div>
                                <div
                                    className="w-1/4 text-center bg-withdrawalbg text-black font-poppins border p-2 px-1 rounded-3xl truncate"
                                    title="Amount"
                                >
                                    Amount
                                </div>
                                <div
                                    className="w-1/4 text-center bg-withdrawalbg text-black font-poppins border p-2 px-1 rounded-3xl truncate"
                                    title="Status"
                                >
                                    Status
                                </div>
                                <div
                                    className="w-1/4 text-center bg-withdrawalbg text-black font-poppins border p-2 px-1 rounded-3xl truncate"
                                    title="Date"
                                >
                                    Date
                                </div>
                            </div>

                            {/* Data Row */}
                            <div className="bg-blackbackgound w-full h-full p-6 rounded-b-lg">
                                <div className="flex justify-center items-center">
                                    <div className="text-white font-bold flex items-center gap-3">
                                        <img src={crose} alt="No Data Found" />
                                        <span>No Data Found</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddAccount;
