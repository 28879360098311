import { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "./config";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => JSON.parse(localStorage.getItem("user")) || null);
  const [token, setToken] = useState(() => localStorage.getItem("token") || null);
  const [refreshToken,setRefreshToken] = useState(() => localStorage.getItem("refreshToken") || null);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const login = async (token,refreshToken) => {
    localStorage.setItem("token", token);
    localStorage.setItem("refreshToken", refreshToken);
    setToken(token);
    setRefreshToken(refreshToken);
    await fetchUserProfile(token,refreshToken);
  };

  const logout = (() => {
    let isLoggedOut = false;
    return () => {
      if (isLoggedOut) return;
      isLoggedOut = true;

      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken")
      setUser(null);
      setToken(null);
    };
  })();

  const refreshTokens = async () => {
    if (isRefreshing) return;
    setIsRefreshing(true);

    try {
      const response = await axios.post(`${BASE_URL}/auth/refreshtoken`, {
        refreshToken,
      });

      if (response.data.success) {
        const newToken = response.data.result[0]?.token;
        localStorage.setItem("token", newToken);
        setToken(newToken);
      } else {
        console.error("Failed to refresh token:", response.data.message);
        logout();
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      logout();
    } finally {
      setIsRefreshing(false);
    }
  };

  const fetchUserProfile = async (currentToken) => {
    let authToken = currentToken || token;
    if (!authToken) return;

    try {
      const response = await axios.get(`${BASE_URL}/auth/userprofile`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });

      if (response.data.success) {
        const userData = response.data.result[0]?.userDetail;
        if (userData) {
          localStorage.setItem("user", JSON.stringify(userData));
          setUser(userData);
        } else {
          logout();
        }
      } else {
        if (response.data.result === "jwt expired") {
          await refreshTokens();
        } else {
          logout();
        }
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
      if (error.response?.data?.result === "jwt expired") {
        await refreshTokens();
      } else {
        logout();
      }
    }
  };

  useEffect(() => {
    if (token) {
      fetchUserProfile(token);
    }
  }, [token]);

  return (
    <AuthContext.Provider value={{ user, token, login, logout, fetchUserProfile }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
