import React, { useState } from "react";
import { RiFileCopyFill } from "react-icons/ri";
import { ImCross } from "react-icons/im";

const WithDrawPop = ({ isPopupOpen, setIsPopupOpen }) => {
  const [withdrawalId] = useState("12345678905665664");

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

 
  const getMaskedWithdrawalId = (id) => {
    const firstPart = id.slice(0, 3); 
    const maskedPart = "*".repeat(id.length - 3); 
    return firstPart + maskedPart;
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(withdrawalId).then(() => {
      alert("Withdrawal ID copied to clipboard!");
    });
  };

  return (
    <div>
      {/* Popup Modal */}
      {isPopupOpen && (
        <div className="fixed inset-0 flex justify-center items-center z-50 bg-opacity-80 backdrop-blur-sm bg-black"          
>
            
          {/* Popup Content */}
          <div className="relative w-[800px] h-[350px] rounded-md shadow-lg bg-white ">
            {/* Modal Header */}
          <div className="flex text-lg font-bold text-center text-white bg-timebg justify-center p-2 gap-x-2">
                      <h2>Timer</h2>
        
                  
                      <div className="absolute right-2 top-2 cursor-pointer">
                  <ImCross onClick={handleClosePopup} />
                </div>
                    </div>
            {/* Modal Body */}
            <div className="justify-center flex items-center mt-24">
              <div className="w-full max-w-[250px]">
                <label
                  htmlFor="withdrawalId"
                  className="block text-xl uppercase font-bold text-gray-700"
                >
                  WITHDRAWAL ID:
                </label>
                <div className="flex items-center mt-2 relative">
                  <input
                    type="text"
                    id="withdrawalId"
                    value={getMaskedWithdrawalId(withdrawalId)} 
                    readOnly
                    className="block w-full px-6 py-4 text-white bg-transtioncolor  rounded-md shadow-sm focus:outline-none sm:text-sm"
                  />
                  <button
                    onClick={handleCopy}
                    className="text-timebg absolute left-52 text-white rounded-md focus:outline-none"
                  >
                    <RiFileCopyFill size={24} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WithDrawPop;
