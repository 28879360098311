import React, { useEffect, useState } from "react";
import { ImCross } from "react-icons/im";
import { LuAlarmClock } from "react-icons/lu";
import "../Spinner/Spinner.css";
import DepositPopUp from "./DepositPopUp";
const PopupWithBlur = ({ isPopupOpen, setIsPopupOpen }) => {
  const [openDepositPopUp,setOpenDepositPopUp] = useState(false)
  const [timeLeft, setTimeLeft] = useState(10); 

  useEffect(() => {
    let timer;
    if (isPopupOpen) {
      setTimeLeft(10); 

      timer = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            setIsPopupOpen(false);
            setOpenDepositPopUp(true)
            return 0;
          }
          return prev - 1; // Decrease time by 1 second
        });
      }, 500);
    }

    return () => clearInterval(timer); // Cleanup on unmount
  }, [isPopupOpen]);

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div className="max-w-[500px]">
      {isPopupOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          {/* Blurred Background */}
          <div className="absolute inset-0 bg-black bg-opacity-80 backdrop-blur-sm"></div>

          {/* Popup Content */}
          <div className="relative bg-white w-120 rounded-md shadow-lg">
            {/* Timer Header */}
            <div className="flex text-lg font-bold text-center text-white bg-timebg justify-center p-2 gap-x-2">
              <h2>Timer</h2>
              <LuAlarmClock size={28} />
              <div className="absolute right-2 top-2 cursor-pointer">
                <ImCross onClick={handleClosePopup} />
              </div>
            </div>

            {/* Instructions */}
            <ul className="text-sm text-red font-poppins space-y-2 p-5">
              <li>
                1. The bank accounts for withdrawal must have the same name as the user registration name.
              </li>
              <li>2. All withdrawals will be sent to the added bank account.</li>
              <li>
                3. If you need to make any changes, you will need to contact our support team.
              </li>
              <li>4. Withdrawal requests will be processed within 24 hours.</li>
            </ul>

            {/* Timer Button */}
            <div className="text-center mt-4">
              <button className="bg-caribbeangreen-200 text-white px-8 mb-4 py-2 rounded-md">
                {Math.floor(timeLeft / 10)} Min : {timeLeft % 10} Sec
              </button>
            </div>

            {/* Loader */}
            <div className="flex justify-center items-center mt-4 mb-8">
              <div className="spinner"></div>
            </div>
          </div>
        </div>
      )}
      <DepositPopUp  isPopupOpen={openDepositPopUp} setIsPopupOpen={setOpenDepositPopUp} />
    </div>

  );
};

export default PopupWithBlur;
