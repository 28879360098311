import React, { useState } from "react";
import Navbar from "../Pages/Navbar";
import SideBar from "../Pages/SideBar";
import { Outlet } from "react-router-dom";

function Dashboard() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <div
      className="dashboard-container flex flex-col lg:flex-row h-screen bg-black"
      style={{
        backgroundImage: `url('/assets/images/cricket.svg'), linear-gradient(180deg, rgba(0, 0, 0, 0) 69%, #000000 93.5%)`,
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
     
          <SideBar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
   
     <div className="main-content flex flex-col w-full">
     <header
           className={`bg-gray-900 text-white fixed z-10 w-full ${isSidebarOpen ? 'lg:max-w-[80%] ' : ' lg:max-w-full'}`}
            style={{
    msOverflowStyle: "none",
    scrollbarWidth: "none",
  }}
>

    <Navbar setIsSidebarOpen={setIsSidebarOpen} isSidebarOpen={isSidebarOpen} />
  </header>

  <main
    className="content-wrapper overflow-y-auto lg:pt-16 md:pt-16 sm:pt-28 pt-28   "  
    style={{
      msOverflowStyle: "none",
      scrollbarWidth: "none",
    }}
  >
   <div
  className={`  content-inner no-scrollbar bg-black   flex flex-col min-h-screen h-full ${
    isSidebarOpen ? "lg:max-w-[100%]" : "lg:max-w-full"
  }`}
  style={{
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    overflowY: "auto",
    
  }}
>
  <Outlet />
</div>

  </main>
</div>


   
    </div>
    
  );
}

export default Dashboard;
