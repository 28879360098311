import React, { useState, useEffect } from "react";

const EighteenPluxModal = ({isOpen, setIsOpen}) => {

  useEffect(() => {
    const isVerified = localStorage.getItem("ageVerified");
    if (!isVerified) {
      setIsOpen(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("ageVerified", "true");
    setIsOpen(false);
  };

  const handleExit = () => {
    window.location.href = "https://www.google.com"; 
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
      <div className="bg-[#12172B] text-white p-6 rounded-lg shadow-lg w-96 text-center">
        <h2 className="text-4xl font-bold text-pink-500">18+</h2>
        <h3 className="text-lg font-semibold mt-2">USERS ONLY</h3>
        <p className="text-gray-300 text-sm mt-2">
          Please confirm that you’re over 18 or leave the website.
        </p>
        <div className="flex justify-between mt-6">
          <button
            onClick={handleAccept}
            className="w-1/2 mr-2 px-4 py-2 border border-borderColor text-white rounded-lg "
          >
            I'M OVER 18
          </button>
          <button
            onClick={handleExit}
            className="w-1/2 ml-2 px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
          >
            EXIT
          </button>
        </div>
      </div>
    </div>
  );
};

export default EighteenPluxModal;
