import React, { useEffect, useRef, useState } from "react";
import { ImCross } from "react-icons/im";
import { LuAlarmClock } from "react-icons/lu";
import "../Spinner/Spinner.css";
import WithDrawPop from "./WithDrawPop";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../Services/config";
const WithDrawTimer = ({ isPopupOpen, setIsPopupOpen, successMessage, setSuccessMessage }) => {
  const [openDepositPopUp, setOpenDepositPopUp] = useState(false);
  const [setting, setSetting] = useState([]);
  const [timeLeft, setTimeLeft] = useState(null);
  const hasToastFired = useRef(false);
  const settingList = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/order/get-setting`, {
        withCredentials: true,
        headers: { "Content-Type": "application/json" },
      });

      const settingData = response.data.result?.flatMap((item) => item.settingAll) || [];
      setSetting(settingData);
    } catch (err) {
      console.error("Error fetching settings:", err);
    }
  };

  useEffect(() => {
    settingList();
  }, []);

  useEffect(() => {
    if (isPopupOpen && setting.length > 0) {
      const widraw_time = setting[0]?.widraw_timer;

      if (widraw_time) {
        const totalSeconds = widraw_time * 60;
        setTimeLeft(totalSeconds);
        hasToastFired.current = false;

        const timer = setInterval(() => {
          setTimeLeft((prev) => {
            if (prev <= 1) {
              clearInterval(timer);
              setIsPopupOpen(false);

              if (!hasToastFired.current) {
                // toast.success(successMessage);
                hasToastFired.current = true;
              }
              return null;
            }
            return prev - 1;
          });
        }, 1000);

        return () => clearInterval(timer);
      }
    }
  }, [isPopupOpen, setting]);


  return (
    <div className="max-w-[500px]">
      {isPopupOpen && timeLeft !== null && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-sm"></div>
          <div className="relative bg-white w-120 rounded-md shadow-lg">
            <div className="flex text-lg font-bold text-center text-white bg-timebg justify-center p-2 gap-x-2">
              <h2>Timer</h2>
              <LuAlarmClock size={28} />
              <div className="absolute right-2 top-2 cursor-pointer">
                <ImCross onClick={() => setIsPopupOpen(false)} />
              </div>
            </div>

            <ul className="text-sm text-red font-poppins space-y-2 p-5">
              <li>1. The bank accounts for withdrawal must have the same name as the user registration name.
                <br />निकासी के लिए बैंक खाते का नाम उपयोगकर्ता पंजीकरण नाम से मेल खाना चाहिए।</li>

              <li>2. All withdrawals will be sent to the added bank account.
                <br />सभी निकासी जोड़े गए बैंक खाते में भेजी जाएंगी।</li>

              <li>3. If you need to make any changes, you will need to contact our support team.
                <br />यदि आपको कोई बदलाव करने की आवश्यकता है, तो आपको हमारी सहायता टीम से संपर्क करना होगा।</li>

             
            </ul>


            <div className="text-center mt-4">
              <button className="bg-caribbeangreen-200 text-white px-8 mb-4 py-2 rounded-md">
                {Math.floor(timeLeft / 60)} Min : {timeLeft % 60} Sec
              </button>
            </div>

            <div className="flex justify-center items-center mt-4 mb-8">
              <div className="spinner"></div>
            </div>
          </div>
        </div>
      )}
      <WithDrawPop isPopupOpen={openDepositPopUp} setIsPopupOpen={setOpenDepositPopUp} />
    </div>
  );
};

export default WithDrawTimer;
