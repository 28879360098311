import React, { useEffect, useRef, useState } from "react";

import { FaArrowLeftLong } from "react-icons/fa6";
import { FaArrowRightLong } from "react-icons/fa6";
import Slider from "react-slick";
import { images, categoryData } from "../../Data/Data";
import axios from "axios";

function HomeBeforeLogin() {
  const sliderRef = useRef(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('CRICKET');
  const scrollLeft = () => {
    sliderRef.current.scrollBy({
      left: -300,
      behavior: 'smooth',
    });
  };

  const scrollRight = () => {
    sliderRef.current.scrollBy({
      left: 300,
      behavior: 'smooth',
    });
  };


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,

  };
  const [matches, setMatches] = useState([]);

  const apiKey = "6b57d6cc-574f-4e20-9b5b-821ea5e7e26d";
  const apiUrl = `https://api.cricapi.com/v1/currentMatches?apikey=${apiKey}&offset=0`;

  useEffect(() => {
    axios.get(apiUrl)
      .then(response => {
        setMatches(response.data.data);
      })
      .catch(error => console.error("Error fetching data:", error));
  }, []);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };
  const handleCardClick = (index) => {
    setSelectedCard(index);
  };
  return (
    <div
      style={{
        backgroundImage: `url('/assets/images/cricket.svg'), linear-gradient(180deg, rgba(0, 0, 0, 0) 69%, #000000 93.5%)`,
        backgroundAttachment: 'fixed',
      }}
      className="bg-black h-auto"
    >

      <div className="p-1.5">



        <div className="w-full mx-auto 
">
          <Slider {...settings} className="w-full">
            {images.map((src, index) => (
              <div key={index} className="w-full">
                <img src={src} alt={`Slide ${index + 1}`} className="w-full h-auto object-cover" />
              </div>
            ))}
          </Slider>
        </div>





        <div className="mt-5">
          <div className="h-auto max-w-[98%]  mx-auto rounded-lg bg-withdrawalbg p-3 ">
            {/* Scrollable Container */}
            <div className="flex gap-3 flex-nowrap overflow-x-auto scrollbar-hide no-scrollbar" style={{
              msOverflowStyle: "none", /* IE 10+ */
              scrollbarWidth: "none", /* Firefox */
            }}>

              {Object.keys(categoryData).map((category) => (
                <div
                  key={category}
                  className={`border-dashed border-2 rounded-full px-5 text-center flex items-center justify-center ${selectedCategory === category
                    ? 'bg-black text-white'
                    : 'bg-white text-black'
                    } border-sky-500 cursor-pointer`}
                  onClick={() => handleCategoryClick(category)}
                >
                  {category}
                </div>
              ))}

            </div>
          </div>
          {/* <div className="text-white justify-self-end p-4">
            <button className="bg-withdrawalbg p-3 px-10 border border-withdrawalbg font-bold text-black rounded-full">
              VIEW BY TIME
            </button>
          </div> */}
          <div className="h-auto border border-1 border-sky-500 border-borderColor mt-2">
            <div className="h-[42px] w-full  bg-withbg flex justify-start items-center px-4 md:px-9">
              <div className="text-white">Game</div>

            </div>
            <div>



            </div>
            <div className="text-white flex p-5">
              <div className="text-sm md:text-base ml-1">
                {matches?.map((item, index) => (
                  <div key={index} className="flex items-center space-x-5 p-1">
                    <img src="\assets\images\iconcricket.svg" alt="AddImage" className="w-6 h-6" />
                    <p>{item?.name}</p>
                  </div>
                ))}
              </div>
            </div>

            <div className="text-white bg-richblack-800 w-80 h-12 mx-auto flex items-center justify-center rounded-lg m-4 " >

              View Lead Boards
            </div>


          </div>



          <div className="my-8" >
            <div className="text-white font-bold text-3xl font-Poppins">
              Upcoming Match’s
            </div>
            <div className="relative my-5">
              {/* Left Button */}
              <div className="flex justify-end m-3 text-white gap-x-2">   <button
                onClick={scrollLeft}


              >
                <FaArrowLeftLong />
              </button>
                <button
                  onClick={scrollRight}
                >
                  <FaArrowRightLong />
                </button>  </div>

              <div
                ref={sliderRef}
                className="flex gap-x-4 overflow-x-scroll scroll-smooth px-5 md:px-0 no-scrollbar"
                style={{
                  scrollbarWidth: "none",
                  msOverflowStyle: "none",
                }}
              >
                {/* Cards */}
                {[...Array(4)].map((_, idx) => (
                  <div
                    key={idx}
                    onClick={() => handleCardClick(idx)}
                    className={`max-w-xs sm:max-w-sm md:max-w-sm flex-shrink-0 rounded-lg shadow-lg bg-transtioncolorbg text-white p-4 border 
            ${selectedCard === idx
                        ? "border-blue-500" // Selected border color
                        : "border-borderColor" // Default border color
                      }`}
                  >
                    <div className="relative">
                      <img
                        src="https://s3-alpha-sig.figma.com/img/a1b5/ad7f/e9c763e5b6e2ba370b82ffc75f0a0dbb?Expires=1737331200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ol1xeC8YWvRMDaZweKnNdoBJTghLoR2hG2X~72Iq21caURLyebLwTR9pFoGBwmNNAcuJCbNQKB28BetEX-xr34joBzzhTpg4pnDl2JtKvEhj3fjCQQGb8oZvKN4UQXim-nnKAClIb222Xemv~L2hSmsTymvqAW3EKyEPdU-ZFKRSnaxGpa8DY7ifdrWv2WfLpzsI5IqHkOWtm7Lz8cKm882KzayyHmLyV-7804nrW2CfNcUeupoRuoadM9tM5OzTouC4hV8Y8R2ThBiIaQ~xDjrAp8PG1Hzqmjg2WRZRtShR-1~-12JO~QN3pWC2w1FOTaQuzIHvukYfO8c2xV6atw__"
                        alt="Soccer"
                        className="w-full rounded-t-lg"
                      />
                      <span className="absolute flex gap-x-2 bg-black rounded-xl left-0 bg-red-500 text-white text-xs font-semibold px-3 py-2 rounded-tl-lg z-10 w-32 -bottom-4 mx-3">
                        <img src="assets/images/score.png" width="15x" />
                        Soccer
                      </span>
                    </div>
                    <p className="text-sm text-gray-400 mt-5">21 Feb - 6 Mar, 08:00 PM</p>
                    <h2 className="text-lg font-semibold mt-2">Soccer</h2>
                    <p className="text-sm text-gray-300 mt-1">
                      The World's Most Popular Sport, Soccer Unites Fans With Its Dynamic Gameplay,
                      Iconic Players, And International Tournaments. A Game Of Skill, Teamwork,
                      And Passion, It Transcends Boundaries.
                    </p>
                  </div>
                ))}
              </div>


              {/* Right Button */}

            </div>
          </div>


        </div>

      </div>
    </div>
  );
}

export default HomeBeforeLogin;
