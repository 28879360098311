import React, { useEffect, useRef, useState } from 'react';
import PopUpLogin from "../../Pop-Up/NewIdPopUp";
import { FaArrowLeftLong, FaArrowRightLong } from 'react-icons/fa6';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BASE_URL } from '../../../Services/config';
import { useAuth } from '../../../Services/AuthContext';
import { FaSyncAlt } from 'react-icons/fa';
import CreateNewIdTimer from '../../Pop-Up/CreateNewIdTimer';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { formatDateTime } from '../../../Services/config';
function CreateNewId() {
    const { user, token, fetchUserProfile } = useAuth()
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [category, setCategories] = useState([])
    const [gameList, setGameList] = useState([])
    const [username, setUsername] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [requred, setRequred] = useState("")
    const [setting, setSetting] = useState([])
    const [popupOpen, setPopupOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState("")
    const [showPassword, setShowPassword] = useState(false);

    const handleCardSelect = (id) => {
        setSelectedCard((prevSelected) => (prevSelected === id ? null : id));
    };
    const fetchGameList = async () => {
        try {

            const response = await axios.get(`${BASE_URL}/auth/game-account-list?userId=${user.id}`, {
                withCredentials: true,
                headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            });

            const portalNames = response.data.result?.flatMap(item => item.accountListAll) || [];

            setGameList(portalNames);
        } catch (err) {
            console.error("Error fetching orders:", err);
        }
    };

    const fetchOrders = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/order/portal-list`, {
                withCredentials: true,
                headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            });

            const gameList = response.data.result?.flatMap(item => item.portalList) || [];

            setCategories(gameList);
        } catch (err) {
            console.error("Error fetching orders:", err);
        }
    };

    useEffect(() => {
        fetchGameList();
        fetchOrders();
    }, []);


    const handleCreateID = async () => {
        if (!selectedCard || username.trim() === "") {
            setRequred("Required Field");
            return;
        }
        setIsLoading(true);

        try {
            const payload = {
                username,
                portalName: category.find((item) => item.id === selectedCard)?.name || "",
                portalId: selectedCard,
                userId: user.id,
            };

            const response = await axios.post(`${BASE_URL}/auth/gameaccountcreate`, payload, {
                headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
                withCredentials: true,
            });
            if (!response.data?.success) {
                toast.error(response?.data?.message || "Something went wrong");
                return;
            }
            toast.success(response?.data?.message)
            setSuccessMessage(response?.data?.message)
            setPopupOpen(true)
            setUsername("");
            setSelectedCard(null);
            setUsername("")
            setRequred("");
            fetchGameList();
        } catch (error) {
            const errorMessage = error.response?.data?.message || "Something went wrong";
            toast.error(errorMessage);
        }
        finally {
            setIsLoading(false);
        }
    };

    const sliderRefs = useRef(null);
    const sliderRef = useRef(null);

    const scrollLefts = () => {
        sliderRefs.current.scrollBy({
            left: -300,
            behavior: 'smooth',
        });
    };

    const scrollRights = () => {
        sliderRefs.current.scrollBy({
            left: 300,
            behavior: 'smooth',
        });
    };


    const scrollLeft = () => {
        if (sliderRef.current) {
            sliderRef.current.scrollBy({
                left: -300,
                behavior: "smooth",
            });
        }
    };

    const scrollRight = () => {
        if (sliderRef.current) {
            sliderRef.current.scrollBy({
                left: 300,
                behavior: "smooth",
            });
        }
    };

    const settingList = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/order/get-setting`, {
                withCredentials: true,
                headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            });
            const setting = response.data.result?.flatMap(item => item.settingAll) || [];


            setSetting(setting);
        } catch (err) {
            console.error("Error fetching orders:", err);
        }
    };


    useEffect(() => {
        settingList();
    }, []);
    const images = setting.map((item) => item.s3_img);
    const itemsPerPage = 5;
    const [currentPage, setCurrentPage] = useState(1);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const totalPages = Math.ceil((gameList?.length || 0) / itemsPerPage);
    const currentItems = (gameList || []).slice(startIndex, startIndex + itemsPerPage);
    const portalList = category.filter((b) => b.live === "YES")

    return (
        <div className="flex justify-center bg-mainColor w-full h-full p-4">
            <div className="border border-pure-greys-300 p-4 rounded-xl w-full  h-fit ">


                <div className="flex justify-between  m-2">
                    <div className="font-poppins text-lg text-withdrawalbg">
                        Select the Portal
                    </div>

                    {portalList.length > 4 && (
                        <div className="flex justify-end  text-white gap-x-2">

                            <button onClick={scrollLeft} className="bg-gray-800 p-2 rounded-full text-white">
                                <FaArrowLeftLong />
                            </button>


                            <button onClick={scrollRight} className="bg-gray-800 p-2 rounded-full text-white">
                                <FaArrowRightLong />
                            </button>

                        </div>

                    )}

                </div>
                {/* Card's section  */}
                <div className=" bg-bg-blackbackgound">
                    <div ref={sliderRef}
                        className="w-full overflow-x-auto scrollbar-hide " style={{
                            scrollbarWidth: "none",
                            msOverflowStyle: "none",
                        }}>
                        <div className="flex gap-4">
                            {portalList.map((item, index) => (
                                <div
                                    key={item.id}
                                    onClick={() => handleCardSelect(item.id)}
                                    className={`border-3 p-3 space-y-3 rounded-xl bg-borderback w-[200px] flex-shrink-0 cursor-pointer ${selectedCard === item.id
                                        ? 'border-2 border-white bg-white/50 shadow-white)]'
                                        : 'border-transparent bg-borderback'
                                        }`}

                                >


<div className="flex justify-center items-center mb-2  sm:h-auto max-h-[150px] ">
                      <img src={`${images}${item.img}`} className="w-full max-h-[150px]  object-fill" />
                    </div>

                                    <div>
                                        <button className="bg-withdrawalbg w-[170px] h-[35px] rounded-lg text-black font-bold font-poppins">
                                            {item.name}
                                        </button>

                                    </div>
                                </div>
                            ))}
                        </div>
                        {!selectedCard && <div className='text-red text-xs m-1'>{requred}</div>}

                    </div>

                    <div className="flex flex-wrap gap-4 pt-4">
                        <div className="w-full sm:w-1/3">
                            <label htmlFor="username" className="block text-md font-medium text-white">
                                Username:
                            </label>
                            <input
                                type="text"
                                id="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                placeholder="Enter Your Username"
                                className="mt-1 block w-full px-3 py-3 rounded-md shadow-sm focus:outline-none sm:text-sm"
                            />
                            {!username && <div className='text-red text-xs m-1'>{requred}</div>}
                        </div>
                        <div className="w-full sm:w-auto">
                            <button
                                onClick={handleCreateID}
                                disabled={isLoading}
                                className={`uppercase bg-green text-white font-semibold font-poppins rounded-lg py-3 px-6 w-full lg:mt-6 md:mt-6 sm:mt-6 sm:w-auto 
                ${isLoading ? "opacity-50 cursor-not-allowed" : ""}`}
                            >
                                {isLoading ? "Creating..." : "Create ID"}
                            </button>
                        </div>
                    </div>
                </div>




                <div className="w-full mt-3">


                    <div
                        className="bg-blackbackgound w-full h-full p-2 rounded-lg overflow-x-auto"
                        style={{
                            scrollbarWidth: "none",
                            msOverflowStyle: "none",
                        }}
                    >
                        <div className="flex justify-between m-3 text-white gap-x-2 pt-2">
                            <div> Your Portal List</div>
                            <div className='flex justify-between gap-x-2'>
                                <div className=' flex gap-2  sm:hidden'>
                                    <button onClick={scrollLefts}>
                                        <FaArrowLeftLong />
                                    </button>
                                    <button onClick={scrollRights}>
                                        <FaArrowRightLong />
                                    </button>

                                </div>
                                <button
                                    onClick={() => window.location.reload()}
                                    className="bg-blue-500 text-white px-3 ml-2 py-1 rounded-md shadow-md hover:bg-blue-600 transition flex items-center gap-2"
                                >
                                    <FaSyncAlt />
                                    <span className="hidden sm:inline">Refresh</span>
                                </button>
                            </div>
                        </div>
                        <div
                            ref={sliderRefs}
                            className="w-full overflow-x-auto bg-blackbackgound rounded-lg"
                            style={{
                                scrollbarWidth: "none",
                                msOverflowStyle: "none",
                            }}
                        >
                            <div className="min-w-max">

                                <div className="sticky top-0 grid grid-cols-7 bg-topbg text-white font-bold px-4 py-2 rounded-md text-sm gap-x-3 min-w-[600px]">
                                    <div className="text-center bg-withdrawalbg text-black font-poppins border px-3 py-1 rounded-3xl truncate">
                                        S.No.
                                    </div>
                                    <div className="text-center bg-withdrawalbg text-black font-poppins border px-3 py-1 rounded-3xl truncate">
                                        User Name
                                    </div>
                                    <div className="text-center bg-withdrawalbg text-black font-poppins border px-3 py-1 rounded-3xl truncate">
                                        Portal Name
                                    </div>
                                    <div className="text-center bg-withdrawalbg text-black font-poppins border px-3 py-1 rounded-3xl truncate">
                                        Created At
                                    </div>
                                    <div className="text-center bg-withdrawalbg text-black font-poppins border px-3 py-1 rounded-3xl truncate">
                                        Portal User Name
                                    </div>
                                    <div className="text-center bg-withdrawalbg text-black font-poppins border px-3 py-1 rounded-3xl truncate">
                                        Portal Password
                                    </div>
                                    <div className="text-center bg-withdrawalbg text-black font-poppins border px-3 py-1 rounded-3xl truncate">
                                        Status
                                    </div>
                                </div>


                                <div>
                                    {


                                        currentItems && currentItems.length > 0 ? (
                                            currentItems.map((transaction, index) => (
                                                <div
                                                    key={index}
                                                    className="grid grid-cols-7 text-white border-b border-gray-700 px-4 py-3 text-sm gap-2 min-w-[600px]"
                                                >
                                                    <div className="text-center truncate">{startIndex + index + 1}.</div>
                                                    <div className="text-center truncate">{transaction.username || "N/A"}</div>
                                                    <div className="text-center truncate">{transaction.portalName || "N/A"}</div>
                                                    <div className="text-center truncate">
                                                        {(formatDateTime(transaction.createdAt))}
                                                    </div>
                                                    <div className="text-center truncate">
                                                        {transaction.portalUserName || "N/A"}
                                                    </div>
                                                    <div className="text-center truncate">
                                                        {transaction.portalPassword || "N/A"}
                                                    </div>
                                                    <div
                                                        className={`text-center truncate rounded-md p-1 
        ${transaction.status === "SUCCESS" ? "bg-darkGreen" :
                                                                transaction.status === "FAIL" ? "bg-red" :
                                                                    "bg-liteBlue"}`}
                                                    >
                                                        {transaction.status || "N/A"}
                                                    </div>

                                                </div>
                                            ))
                                        ) :
                                            (
                                                <p className="text-white justify-center flex text-center pt-2">No data found</p>
                                            )
                                    }
                                </div>
                            </div>
                        </div>
                        {currentItems && currentItems.length > 0 ? (
                            <div className="w-full flex justify-center mt-4">
                                <div className="flex items-center gap-3">
                                    <button
                                        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                                        disabled={currentPage === 1}
                                        className={`w-10 h-10 flex items-center justify-center bg-gray-700 text-white rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-500"
                                            }`}
                                    >
                                        <FaArrowLeftLong />
                                    </button>

                                    <span className="text-white text-sm">
                                        Page {currentPage} of {totalPages}
                                    </span>

                                    <button
                                        onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                                        disabled={currentPage == totalPages}
                                        className={`w-10 h-10 flex items-center justify-center bg-gray-700 text-white rounded ${currentPage == totalPages ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-500"
                                            }`}
                                    >
                                        <FaArrowRightLong />
                                    </button>
                                </div>
                            </div>) : null}

                    </div>
                </div>






            </div>
            {isModalOpen && <PopUpLogin isPopupOpen={isModalOpen} setIsPopupOpen={setIsModalOpen} />}
            <CreateNewIdTimer isPopupOpen={popupOpen} setIsPopupOpen={setPopupOpen} successMessage={successMessage} />
        </div>
    );
}

export default CreateNewId;