export default function ResponsibleGaming() {
    const logo = "/assets/logo/logobg.svg";
    return (
      <div className="bg-mainColor min-h-screen flex justify-center">
        <div className="text-white rounded-lg shadow-lg w-full max-w-3xl">
          {/* Header Section */}
          <div className="bg-mainColor w-full py-2 shadow-md flex flex-col items-start gap-2 text-3xl font-bold fixed top-0 left-0 right-0 px-4">
            <img src={logo} alt="Company Logo" />
            <div>Responsible Gaming Policy</div>
          </div>
  
          {/* Content Section */}
          <div className="p-4 bg-richblack-700 rounded-lg mt-36">
            <h2 className="text-lg font-bold mt-4">Play Responsibly</h2>
            <p className="mt-2 text-sm leading-6">
              At SSBook - Saawaliya Syndicate, we are committed to promoting responsible gaming and ensuring a safe betting environment for all users. Gambling should be a form of entertainment, not a way to generate income.
            </p>
  
            <h2 className="text-lg font-bold mt-6">Stay in Control</h2>
            <p className="mt-2 text-sm leading-6">If you feel that gambling is affecting your personal life, finances, or mental well-being, we strongly encourage you to:</p>
            <ul className="mt-2 text-sm leading-6 list-disc pl-6">
              <li>✅ Set deposit limits, loss limits, or session limits to control your gaming activity.</li>
              <li>✅ Use our self-exclusion options if you need a break from gambling.</li>
              <li>✅ Seek help from professional organizations specializing in gambling addiction support.</li>
            </ul>
  
            <h2 className="text-lg font-bold mt-6">Remember the Risks</h2>
            <p className="mt-2 text-sm leading-6">
              🛑 Gambling involves financial risk. Please play within your means and avoid chasing losses.
            </p>
  
            <h2 className="text-lg font-bold mt-6">Support & Assistance</h2>
            <p className="mt-2 text-sm leading-6">For support and assistance, visit organizations like:</p>
            <ul className="mt-2 text-sm leading-6 list-disc pl-6">
              <li>🔹 <a href="https://www.gamcare.org.uk" className="text-blue-400" target="_blank" rel="noopener noreferrer">GamCare</a></li>
              <li>🔹 <a href="https://www.begambleaware.org" className="text-blue-400" target="_blank" rel="noopener noreferrer">BeGambleAware</a></li>
              <li>🔹 <a href="https://www.gamblersanonymous.org" className="text-blue-400" target="_blank" rel="noopener noreferrer">Gamblers Anonymous</a></li>
            </ul>
  
            <h2 className="text-lg font-bold mt-6">Contact Us</h2>
            <p className="mt-2 text-sm leading-6">
              If you need further assistance, feel free to reach out to SSBook's customer support for responsible gaming tools and resources.
            </p>
          </div>
        </div>
      </div>
    );
  }
  