export default function KYC() {
  const logo = "/assets/logo/logobg.svg";
  return (
    <div className="bg-mainColor min-h-screen flex justify-center">
      <div className="text-white rounded-lg shadow-lg w-full max-w-3xl ">
        {/* Header Section */}
        <div className="bg-mainColor w-full py-2 shadow-md flex flex-col items-start gap-2 text-3xl font-bold fixed top-0 left-0 right-0 px-4">
          <img src={logo} alt="Company Logo" />
          <div>Know Your Customer (KYC) Policy</div>
        </div>

        {/* Content Section */}
        <div className=" p-4 bg-richblack-700 rounded-lg mt-36">
          <h2 className="text-lg font-bold mt-4">Introduction</h2>
          <p className="mt-2 text-sm leading-6">
            SSBook - Saawaliya Syndicate is committed to ensuring a safe, secure, and transparent gaming environment.
            Our KYC (Know Your Customer) Policy helps prevent fraud, identity theft, money laundering, and underage gambling.
          </p>

          <h2 className="text-lg font-bold mt-6">KYC Verification Requirements</h2>
          <p className="mt-2 text-sm leading-6">To comply with regulatory laws, users must provide:</p>
          <ul className="mt-2 text-sm leading-6 list-disc pl-6">
            <li><strong>Proof of Identity:</strong> Government-issued ID (Passport, Driver’s License, or National ID Card).</li>
            <li><strong>Proof of Address:</strong> Utility Bill, Bank Statement, or Rental Agreement (issued within the last 3 months).</li>
            <li><strong>Payment Verification:</strong> Screenshot or statement of the payment method used for deposits/withdrawals.</li>
          </ul>

          <h2 className="text-lg font-bold mt-6">KYC Verification Process</h2>
          <p className="mt-2 text-sm leading-6">
            Users must upload required documents via the SSBook verification portal or email them to our support team.
            KYC verification is usually completed within 24-72 hours. Until verification is completed, SSBook may limit withdrawals and account access.
          </p>

          <h2 className="text-lg font-bold mt-6">Reasons for KYC Verification</h2>
          <ul className="mt-2 text-sm leading-6 list-disc pl-6">
            <li><strong>Age Verification:</strong> Ensuring users meet the legal age requirement (18+ or as per jurisdiction laws).</li>
            <li><strong>Fraud Prevention:</strong> Protecting users from identity theft and money laundering.</li>
            <li><strong>Account Security:</strong> Preventing unauthorized access and financial fraud.</li>
            <li><strong>Regulatory Compliance:</strong> Adhering to international AML (Anti-Money Laundering) and gambling regulations.</li>
          </ul>

          <h2 className="text-lg font-bold mt-6">Failure to Complete KYC</h2>
          <p className="mt-2 text-sm leading-6">If a user fails to provide the necessary documents, SSBook may:</p>
          <ul className="mt-2 text-sm leading-6 list-disc pl-6">
            <li>Restrict deposits, withdrawals, or account access.</li>
            <li>Suspend or permanently close the account.</li>
            <li>Confiscate funds if fraudulent activities are detected.</li>
          </ul>

          <h2 className="text-lg font-bold mt-6">Data Protection & Confidentiality</h2>
          <p className="mt-2 text-sm leading-6">
            All KYC documents submitted are securely stored and encrypted to protect user privacy. SSBook never shares user data with third parties, except in cases required by law or regulatory authorities.
          </p>
        </div>
      </div>
    </div>
  );
}
