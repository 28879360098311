import React, { useRef, useState } from "react";
import { FaSyncAlt, FaWhatsapp } from 'react-icons/fa';
import { RiFileCopyFill } from "react-icons/ri";
import WithDrawPop from "../../Pop-Up/WithDrawPop";
import PopUpLogin from "../../Pop-Up/NewIdPopUp";
import { useEffect } from "react";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../../Services/AuthContext";
import { toast } from "react-toastify";
import { BASE_URL } from "../../../Services/config";
import { BsEye } from "react-icons/bs";
import DepositTimer from "../../Pop-Up/DepositTimer";
import { formatDateTime } from '../../../Services/config';

const DepositeDetails = () => {
  const location = useLocation();
  const imps = "/assets/images/imps.svg";
  const upis = "/assets/images/uips.svg"
  const [isLoading, setIsLoading] = useState(false);
  const { selectedId, selectedOption, selectedId1 } = location.state || {};
  const [isChecked, setIsChecked] = useState(false);
  const [amountInt, setAmounINT] = useState(selectedId)
  const [bankDetail, setBankDetail] = useState([])
  const [requred, setRequred] = useState("")
  const [checkedError, setCheckederror] = useState("")
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [widthPopOpen, setwidthPopupOpen] = useState(false);
  const [loginPopOpen, setLoginPopupOpen] = useState(false);
  const [selectedPreviousOption, setSelectedPreviousOption] = useState(selectedOption);
  const [file, setFile] = useState(null);
  const [amount, setAmount] = useState("");
  const [amounts, setAmounts] = useState("");
  const [preview, setPreview] = useState(null);
  const [setting, setSetting] = useState([])
  const [bank, setBank] = useState([])
  const [totalPages, setTotalPages] = useState("")
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState("")
  const [fileName, setFileName] = useState("");
  const [popupOpen, setPopupOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("")

  const navigate = useNavigate()


  const [depositList, setDepositList] = useState([])
  const { user, token } = useAuth()



  const fetchDepositList = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/order/deposit-list?userId=${user.id}&page=${currentPage}`, {
        withCredentials: true,
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
      });
      const depositList = response.data.result?.flatMap(item => item.depositList.deposits) || [];
      setTotalPages(response.data.result.flatMap((item) => item.depositList.totalPages));
      setLimit(response.data.result.flatMap((item) => item.depositList.limit));

      setDepositList(depositList);
    } catch (err) {
      console.error("Error fetching orders:", err);
    }
  };


  useEffect(() => {
    fetchDepositList(currentPage);
  }, [currentPage]);



  const handleCopy = (value) => {
    console.log('Value to copy:', value);

    if (value) {
      navigator.clipboard.writeText(value).then(() => {
        alert('Copied to clipboard!');
      }).catch((error) => {
        console.error('Clipboard error:', error);
        alert('Failed to copy. Try again.');
      });
    } else {
      alert('No valid data to copy.');
    }
  };


  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFileName(event.target.files[0].name);

    if (selectedFile) {
      setFile(selectedFile);
      const fileURL = URL.createObjectURL(selectedFile);
      setPreview(fileURL);
    }
  };

  const handleAmountChange = (event) => {
    const enteredAmount = event.target.value;
    setAmount(enteredAmount);
  };
  const handleAmountChanges = (event) => {
    const enteredAmount = event.target.value;
    setAmounts(enteredAmount)
  };
  const handleSubmit = async () => {
    if (!amounts || !file) {
      setRequred("Required Field");
      return;
    }

    if (!isChecked) {
      setCheckederror("You must agree to the terms before proceeding.");
      return;
    }

    const formData = new FormData();

    formData.append("utr", amounts);
    formData.append("amount", Number(amountInt));
    formData.append("userId", user.id);
    formData.append("gameAccountId", Number(selectedId1));
    formData.append("mode", selectedPreviousOption);
    formData.append("images", file);

    setIsLoading(true);

    try {

      const response = await fetch(`${BASE_URL}/order/deposit`, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${token}`,
        },
        body: formData,
      });

      const data = await response.json();

      if (data.success === false || !data.result) {
        toast.error(data.message || "Something went wrong.");
        return;
      }

      if (response.ok && data.success) {
        toast.success(data.message)
        setSuccessMessage(data.message);
        setPopupOpen(true)

        // Reset form fields
        setRequred("");
        setAmounts("");
        setSelectedPreviousOption("");
        setAmounINT("");
        setCheckederror("");
        setIsChecked(false);
        fetchDepositList();
      } else {
        toast.error(data.message || "Something went wrong.");
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };



  const sliderRef = useRef(null);

  const scrollLeft = () => {
    sliderRef.current.scrollBy({
      left: -300,
      behavior: 'smooth',
    });
  };

  const scrollRight = () => {
    sliderRef.current.scrollBy({
      left: 300,
      behavior: 'smooth',
    });
  };




  const settingList = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/order/get-setting`, {
        withCredentials: true,
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
      });
      const setting = response.data.result?.flatMap(item => item.settingAll) || [];


      setSetting(setting);
    } catch (err) {
      console.error("Error fetching orders:", err);
    }
  };


  useEffect(() => {
    settingList();
  }, []);
  const images = setting.map((item) => item.s3_img);
  const number = setting.map((item) => item.wa_mobile);
  const wa_mobile_deposit = setting.map((item) => item.wa_mobile_deposit)



  const fetchBankList = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/order/get-bank-account?amount=${selectedId}`, {
        withCredentials: true,
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
      });
      const bank = response.data.result?.flatMap(item => item.bankAccounts) || [];


      setBank(bank);
    } catch (err) {
      console.error("Error fetching orders:", err);
    }
  };


  useEffect(() => {
    fetchBankList();
  }, []);



  const startIndex = (currentPage - 1) * limit;
  return (
    <div className="grid grid-cols-12 gap-4   border-2  border-pure-greys-300   rounded-xl p-1 m-4  bg-black h-fit"
      style={{
        backgroundImage: `url('/assets/images/cricket.svg'), linear-gradient(180deg, rgba(0, 0, 0, 0) 69%, #000000 93.5%)`,
        backgroundAttachment: 'fixed',
      }}
    >
      <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-6 xl:col-span-6 p-4 bg-black h-full ">
        <div className="h-full">

          <div className="bg-transtioncolor">
            <div className="flex gap-4 p-4">
              <div
                className={`w-96 h-[110px] rounded-lg shadow-lg p-4 flex flex-col justify-center cursor-pointer bg-paytmcolor ${selectedPreviousOption === "UPI" ? "border-4 border-white" : "bg-paytmcolor"
                  }`}
              >
                <div className="text-xl font-semibold text-center">
                  <img
                    src={upis}
                    className="w-24 h-auto mx-auto"
                    alt="Paytm"
                  />
                </div>
              </div>

              <div
                className={`w-96 h-[110px] rounded-lg shadow-lg p-4 flex flex-col justify-center cursor-pointer bg-upicolor ${selectedPreviousOption === "IMPS" ? "border-4 border-white" : "bg-upicolor"
                  }`}
              >
                <div className="text-xl font-semibold text-center">
                  <img
                    src={imps}
                    className="w-24 h-auto mx-auto"
                    alt="UPI"
                  />
                </div>
              </div>

              <div
                className={`w-96 h-[110px] rounded-lg shadow-lg p-4 flex flex-col justify-center cursor-pointer bg-qrcolor ${selectedPreviousOption === "QR" ? "border-4 border-white" : "bg-qrcolor"
                  }`}
              >
                <div className="text-sm font-semibold text-center">
                  PAY BY QR
                  <img
                    src="assets/images/scaner.svg"
                    className="w-10 h-auto mx-auto"
                    alt="QR Code"
                  />
                </div>
              </div>        </div>


            <div className="px-4">
              <div className="font-bold p-2">Unique Transaction Reference (UTR):</div>
              <div><input placeholder="Enter UTR" className="w-full max-w-[600px] border-5 h-[40px] px-4" value={amounts}
                onChange={handleAmountChanges} />                           {!amounts && <p className="text-red m-2">{requred}</p>}
              </div>

              <div className="flex flex-col md:flex-row ">
                <div className="w-full md:w-auto">
                  <div className="font-bold p-2">Upload Your Payment Proof (Required)*</div>
                  <div className="p-4">
                    <label htmlFor="file-upload" className="bg-filecolor text-white px-4 py-2 rounded cursor-pointer shadow-lg"
                    >
                      Choose File
                    </label>
                    <input
                      id="file-upload"
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={handleFileChange}
                    />
                    {fileName && <span> {fileName} </span>}
                  </div>
                  {!file && <p className="text-red m-2">{requred}</p>}
                </div>

                <div className="w-full md:w-auto">
                  <div className="font-bold p-2">Amount (INR):</div>
                  <div className="py-4">
                    <input type="number" placeholder="Enter Amount" className="bg-filecolor text-white px-4 h-8 rounded w-full" value={amountInt}
                      onChange={handleAmountChange} />
                  </div>
                </div>
              </div>


              <div className="p-1 flex items-center">
                <input
                  type="checkbox"
                  id="terms"
                  className="mr-1"
                  checked={isChecked}
                  onChange={(e) => setIsChecked(e.target.checked)}
                />
                <label htmlFor="terms" className="text-sm">
                  I have read and agree with the terms of payment and withdrawal policy.
                </label>
              </div>

              {!isChecked && <p className="text-red ml-4">{checkedError}</p>}
              <div className="flex justify-center p-4">
                <button
                  className={`bg-submitbg text-black py-2 px-4 rounded 
                ${isLoading ? "opacity-50 cursor-not-allowed" : ""}`}
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  {isLoading ? "Submitting..." : "Submit"}
                </button>

              </div>
            </div>

          </div>

          {bank?.length > 0 ? (
            bank.map((bankDetail, index) => (
              <div key={index} className="bg-transtioncolorbg p-4 rounded-lg border-2 w-full max-w-xll mx-auto my-2">
                {selectedPreviousOption === "UPI" ? (
                  // UPI Section
                  <div className="grid grid-cols-1 gap-4 p-3 bg-transtioncolor rounded-lg text-center">
                    <h2 className="text-lg font-semibold text-black">UPI Details</h2>
                    <div className="flex items-center justify-between text-sm">
                      <div>
                        <span className="font-bold">UPI ID:</span> <span>{bankDetail.upi}</span>
                      </div>
                      <button
                        className="bg-gray-700 p-1 rounded-md hover:bg-gray-600"
                        onClick={() => handleCopy(bankDetail.upi)}
                      >
                        <RiFileCopyFill size={24} />
                      </button>
                    </div>
                  </div>
                ) : selectedPreviousOption === "QR" ? (
                  <div className="flex flex-col items-center">
                    <div className="flex w-full">
                      <h2 className="text-lg font-semibold text-white mb-2">QR CODE</h2>
                    </div>
                    <div className="flex justify-center items-center">
                      <img
                        src={`${images}${bankDetail.qr}`}
                        alt="Bank Transfer"
                        className="w-40 h-auto rounded-lg"
                      />
                    </div>
                  </div>


                ) : selectedPreviousOption === "IMPS" ? (
                  <div className="grid grid-cols-2 gap-4 p-3 bg-transtioncolor rounded-lg">
                    <h2 className="text-lg font-semibold text-black col-span-2 text-center">
                      Bank Details
                    </h2>

                    {/* Left Section - Bank Info */}
                    <div className="space-y-3">
                      {/* Holder Name */}
                      <div className="flex items-center justify-between text-sm">
                        <div>
                          <span className="font-bold">Holder Name:</span> <span>{bankDetail.holderName}</span>
                        </div>
                        <button
                          className="bg-gray-700 p-1 rounded-md hover:bg-gray-600"
                          onClick={() => handleCopy(bankDetail.holderName, "Holder Name")}
                        >
                          <RiFileCopyFill size={24} />
                        </button>
                      </div>

                      {/* Bank Name */}
                      <div className="flex items-center justify-between text-sm">
                        <div>
                          <span className="font-bold">Bank Name:</span> <span>{bankDetail.bankName}</span>
                        </div>
                        <button
                          className="bg-gray-700 p-1 rounded-md hover:bg-gray-600"
                          onClick={() => handleCopy(bankDetail.bankName, "Bank Name")}
                        >
                          <RiFileCopyFill size={24} />
                        </button>
                      </div>

                      {/* Account Number */}
                      <div className="flex items-center justify-between text-sm">
                        <div>
                          <span className="font-bold">Account No:</span> <span>{bankDetail.accountNumber}</span>
                        </div>
                        <button
                          className="bg-gray-700 p-1 rounded-md hover:bg-gray-600"
                          onClick={() => handleCopy(bankDetail.accountNumber, "Account No")}
                        >
                          <RiFileCopyFill size={24} />
                        </button>
                      </div>

                      {/* IFSC Code */}
                      <div className="flex items-center justify-between text-sm">
                        <div>
                          <span className="font-bold">IFSC Code:</span> <span>{bankDetail.ifsc}</span>
                        </div>
                        <button
                          className="bg-gray-700 p-1 rounded-md hover:bg-gray-600"
                          onClick={() => handleCopy(bankDetail.ifsc, "IFSC Code")}
                        >
                          <RiFileCopyFill size={24} />
                        </button>
                      </div>
                    </div>

                    {/* Right Section - Min/Max Amount */}
                    <div className="space-y-3 flex flex-col bg-transtioncolor justify-center">
                      <div className="text-center p-2 rounded-md text-white bg-black w-30 mx-auto sm:w-24 sm:p-1 md:w-32 text-xs lg:w-40">
                        Min Amount: {bankDetail.min} ₹
                      </div>
                      <div className="bg-gray-800 text-center p-2 rounded-md text-white w-30 mx-auto mt-2 bg-black sm:w-24 md:w-32 sm:p-1 text-xs lg:w-40">
                        Max Amount: {bankDetail.max} ₹
                      </div>
                    </div>
                  </div>

                ) : <div className="bg-transtioncolorbg p-4 rounded-lg border-2 w-full max-w-xll mx-auto my-2 text-white text-center">
                  <h2 className="text-lg font-semibold">No Bank Details Found</h2>
                </div>}
              </div>
            ))
          ) : (
            <div className="bg-transtioncolorbg p-4 rounded-lg border-2 w-full max-w-xll mx-auto my-2 text-white text-center">
              <h2 className="text-lg font-semibold">No Bank Details Found</h2>
            </div>
          )}




          <div className="text-white font-bold p-1 ">
            HOW TO TRANSFER UPI TO BANK CLICK HERE [www.UPTOBANK.info]

          </div>
          <div className="font-sans bg-withdrawalbg uppercase w-full mt-6 p-3 space-x-6 rounded-md flex justify-center items-center" onClick={() => {
            window.open(`https://wa.me/${wa_mobile_deposit}`, "_blank");
          }}>
            For payment-related issues, <span className="font-bold"  >click here.</span>
            <FaWhatsapp size={28} />
          </div>

        </div>
      </div>
      <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-6 xl:col-span-6  h-auto bg-transtioncolorbg">

        <div className="w-full">
          <div className="flex justify-between m-3 text-white gap-x-2">
            <div>Deposit List</div>
            <div className="flex gap-2">
              <button onClick={scrollLeft}>
                <FaArrowLeftLong />
              </button>
              <button onClick={scrollRight}>
                <FaArrowRightLong />
              </button>
              <button
                onClick={() => window.location.reload()}
                className="bg-blue-500 text-white px-3 ml-2 py-1 rounded-md shadow-md hover:bg-blue-600 transition flex items-center gap-2"
              >
                <FaSyncAlt />

                Refresh
              </button>
            </div>
          </div>

          <div className="relative bg-blackbackgound w-full h-full p-2 rounded-b-lg">
            <div
              ref={sliderRef}
              className="w-full overflow-x-auto bg-blackbackgound rounded-b-lg"
              style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
            >
              <div className="min-w-max">
                <div className="grid grid-cols-8 bg-topbg text-white font-bold px-4 py-2 rounded-md text-sm gap-2">
                  {['S.NO.', 'UTR', 'Mode', 'Status', 'Amount(INR)', "Remark", 'CreatedAt', 'File'].map((header, i) => (
                    <div key={i} className="text-center bg-withdrawalbg text-black font-poppins border px-3 py-1 rounded-3xl truncate">
                      {header}
                    </div>
                  ))}
                </div>

                <div>
                  {
                    depositList && depositList.length > 0 ? (
                      depositList.map((transaction, index) => (
                        <div key={index} className="grid grid-cols-8 text-white border-b border-gray-700 px-4 py-3 text-sm gap-2">
                          <div className="text-center truncate">{startIndex + index + 1}</div>
                          <div className="text-center truncate">{transaction.utr || "N/A"}</div>
                          <div className="text-center truncate">{transaction.mode || "N/A"}</div>
                          <div
                            className={`flex items-center justify-center gap-x-1 px-3 py-1 rounded-md truncate ${transaction.status === "SUCCESS" ? "bg-darkGreen" : transaction.status === "REJECTED" ? "bg-redbg" : "bg-liteBlue"
                              }`}
                          >
                            {transaction.status}
                          </div>
                          <div className="text-center truncate">{transaction.amount || "N/A"} </div>
                          <div className="text-center truncate">{transaction.remark || "N/A"}</div>
                          <div className="text-center truncate">{(formatDateTime(transaction.createdAt))}</div>
                          <div className="text-center truncate flex items-center justify-center">
                            <a href={`${images}${transaction.txnFile}`} target="_blank" rel="noopener noreferrer" className="text-center truncate">
                              <BsEye className="w-5 h-5 text-gray-600 hover:text-blue-500 cursor-pointer" />
                            </a>

                          </div>

                        </div>
                      ))
                    ) : (<p className="text-white justify-center flex text-center p-2">No data found</p>)
                  }
                </div>
              </div>
            </div>
          </div>

          {/* Pagination */}
          {
            depositList && depositList.length > 0 ? (
              <div className="w-full flex justify-center mt-4">
                <div className="flex items-center gap-3">
                  <button
                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                    disabled={currentPage === 1}
                    className={`w-10 h-10 flex items-center justify-center bg-gray-700 text-white rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-500"
                      }`}
                  >
                    <FaArrowLeftLong />
                  </button>

                  <span className="text-white text-sm">
                    Page {currentPage} of {totalPages}
                  </span>

                  <button
                    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                    disabled={currentPage == totalPages}
                    className={`w-10 h-10 flex items-center justify-center bg-gray-700 text-white rounded ${currentPage == totalPages ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-500"
                      }`}
                  >
                    <FaArrowRightLong />
                  </button>
                </div>
              </div>
            ) : null}
        </div>

      </div>

      <WithDrawPop isPopupOpen={widthPopOpen} setIsPopupOpen={setwidthPopupOpen} />
      <PopUpLogin isPopupOpen={loginPopOpen} setIsPopupOpen={setLoginPopupOpen} />
      <DepositTimer isPopupOpen={popupOpen} setIsPopupOpen={setPopupOpen} successMessage={successMessage} />
    </div>


  );
};

export default DepositeDetails;
