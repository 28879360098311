export default function ResponsibleGaming() {
    const logo = "/assets/logo/logobg.svg";
    return (
      <div className="bg-mainColor min-h-screen flex justify-center">
        <div className="text-white rounded-lg shadow-lg">
          <div className="bg-mainColor w-full py-2 shadow-md flex flex-col justify-start fixed">
            {/* Header Section */}
            <div className="flex items-center gap-2 text-3xl font-bold">
              <img src={logo} alt="Company Logo" />
            </div>
  
            {/* Responsible Gaming Header */}
            <div className="bg-black text-white text-lg font-bold p-2 mt-4">
              Responsible Gaming Policy
            </div>
          </div>
  
          <div className="mt-36 p-4 bg-richblack-700">
            {/* Responsible Gaming Content */}
            <p className="mt-6 text-md leading-6 font-poppins">
              At <strong>SSBook - Saawaliya Syndicate</strong>, we are committed to promoting responsible gaming and ensuring that our platform remains a safe and enjoyable environment for all users.
            </p>
  
            <h2 className="text-lg font-bold mt-6">Self-Assessment – Are You Gambling Responsibly?</h2>
            <p className="mt-2 text-sm leading-6">
              If you are unsure whether gambling is affecting your life negatively, ask yourself:
            </p>
            <ul className="mt-2 text-sm leading-6 list-disc pl-6">
              <li>🔹 Do you spend more money on gambling than you can afford?</li>
              <li>🔹 Have you ever borrowed money to gamble?</li>
              <li>🔹 Do you chase losses in an attempt to recover lost money?</li>
              <li>🔹 Does gambling interfere with your personal relationships, work, or daily activities?</li>
              <li>🔹 Do you feel anxious, depressed, or irritable when trying to stop gambling?</li>
            </ul>
            <p className="mt-4 text-sm font-bold">
              If you answered “Yes” to any of these questions, you may need to reconsider your gambling habits and seek help.
            </p>
  
            <h2 className="text-lg font-bold mt-6">Responsible Gaming Tools</h2>
            <ul className="mt-2 text-sm leading-6 list-disc pl-6">
              <li><strong>Deposit Limits:</strong> Set daily, weekly, or monthly deposit limits to control spending.</li>
              <li><strong>Loss Limits:</strong> Set a limit on the maximum amount you are willing to lose over a specific period.</li>
              <li><strong>Time Limits & Session Reminders:</strong> Set time limits for gaming sessions and receive automatic reminders.</li>
              <li><strong>Self-Exclusion & Account Suspension:</strong> Exclude yourself from the platform for a set period (e.g., 7 days, 30 days, 6 months, or permanently).</li>
            </ul>
  
            <h2 className="text-lg font-bold mt-6">Preventing Underage Gambling</h2>
            <p className="mt-2 text-sm leading-6">
              SSBook strictly prohibits underage gambling (users below 18 years or the legal gambling age in their jurisdiction).
            </p>
            <ul className="mt-2 text-sm leading-6 list-disc pl-6">
              <li>✔️ <strong>Age Verification:</strong> KYC verification ensures all users meet legal age requirements.</li>
              <li>✔️ <strong>Parental Controls:</strong> Use software like Net Nanny or Cyber Patrol to block gambling websites.</li>
            </ul>
  
            <h2 className="text-lg font-bold mt-6">Gambling Addiction Support & Assistance</h2>
            <p className="mt-2 text-sm leading-6">
              If gambling is negatively affecting your life, seek professional help from:
            </p>
            <ul className="mt-2 text-sm leading-6 list-disc pl-6">
              <li>🌍 GamCare (UK) – <a href="https://www.gamcare.org.uk" className="text-blue-400 underline">www.gamcare.org.uk</a> | 📞 Helpline: 0808 8020 133</li>
              <li>🌍 BeGambleAware (UK) – <a href="https://www.begambleaware.org" className="text-blue-400 underline">www.begambleaware.org</a></li>
              <li>🌍 Gamblers Anonymous – <a href="https://www.gamblersanonymous.org" className="text-blue-400 underline">www.gamblersanonymous.org</a></li>
              <li>🌍 National Council on Problem Gambling (USA) – <a href="https://www.ncpgambling.org" className="text-blue-400 underline">www.ncpgambling.org</a> | 📞 Helpline: 1-800-522-4700</li>
            </ul>
  
            <h2 className="text-lg font-bold mt-6">Fair Play & Ethical Gambling</h2>
            <p className="mt-2 text-sm leading-6">
              SSBook promotes fair play and strictly prohibits:
            </p>
            <ul className="mt-2 text-sm leading-6 list-disc pl-6">
              <li>Cheating or fraudulent activities.</li>
              <li>Exploiting bonus offers through unfair means.</li>
              <li>Using bots or automated software for betting advantages.</li>
            </ul>
            <p className="mt-4 text-sm font-bold">
              Any violation of fair play policies may lead to account suspension or permanent ban.
            </p>
  
            <h2 className="text-lg font-bold mt-6">Final Reminder – Play for Fun, Not for Financial Gain!</h2>
            <p className="mt-2 text-sm leading-6">
              Gambling should be a fun and recreational activity. If you ever feel that gambling is becoming a problem, take a step back and seek help.
            </p>
            <p className="mt-4 text-sm font-bold">
              Need support? Contact our Responsible Gaming Support Team at <a href="mailto:support@ssbook.com" className="text-blue-400 underline">support@ssbook.com</a>.
            </p>
            <p className="mt-4 text-sm font-bold">
              Gambling Can Be Addictive – Please Play Responsibly!
            </p>
          </div>
        </div>
      </div>
    );
  }
  