import React, { useEffect, useRef, useState } from "react";
import PopupWithBlur from "../../Pop-Up/TimePopup";
import WithDrawPop from "../../Pop-Up/WithDrawPop";
import PopUpLogin from "../../Pop-Up/NewIdPopUp";

import { useNavigate } from "react-router-dom";

import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import axios from "axios";
import { useAuth } from "../../../Services/AuthContext";
import { BASE_URL } from "../../../Services/config";
import { BsEye } from "react-icons/bs";
import { FaSyncAlt } from "react-icons/fa";
import { formatDateTime } from "../../../Services/config";
const Deposites = () => {
    const imps = "/assets/images/imps.png";
    const upis = "/assets/images/uips.svg"
    const [selectedOption, setSelectedOption] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [widthPopOpen, setwidthPopupOpen] = useState(false);
    const [loginPopOpen, setLoginPopupOpen] = useState(false);
    const [selectedId, setSelectedId] = useState("");
    const [selectedId1, setSelectedId1] = useState("");
    const [required, setRequred] = useState("")
    const [payment, setPayment] = useState("")
    const [gameList, setGameList] = useState([])
    const [depositList, setDepositList] = useState([])
    const [setting, setSetting] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [totalPages, setTotalPages] = useState("")
    const { user,token,fetchUserProfile } = useAuth()
    const [currentPage, setCurrentPage] = useState(1);

    const handleSelect = (index) => {
        setSelectedOption((prevSelected) => (prevSelected === index ? null : index));

    };


    const fetchGameList = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/auth/game-account-list?userId=${user.id}`, {
                withCredentials: true,
                headers: { "Content-Type": "application/json","Authorization": `Bearer ${token}`},
            });

            const portalNames = response.data.result?.flatMap(item =>
                item.accountListAll?.filter(game => game.status === "SUCCESS") || []
            ) || [];
            setGameList(portalNames);
        } catch (err) {
            console.error("Error fetching orders:", err);
        }
    };


    useEffect(() => {
        fetchGameList();
    }, []);

    const fetchDepositList = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/order/deposit-list?userId=${user.id}&page=${currentPage}`, {
                withCredentials: true,
                headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}`},
            });
            const depositList = response.data.result?.flatMap(item => item.depositList.deposits) || [];
            setTotalPages(response.data.result.flatMap((item) => item.depositList.totalPages));



            setDepositList(depositList);
        } catch (err) {
            console.error("Error fetching orders:", err);
        }
    };


    useEffect(() => {
        fetchDepositList(currentPage);
    }, [currentPage]);

    const handleChange = (event) => {
        setSelectedId(event.target.value);
    };
    const handleChangeOption = (event) => {
        setSelectedId1(event.target.value);
    };
    const sliderRef = useRef(null);

    const scrollLeft = () => {
        console.log('Scrolling Left');
        sliderRef.current.scrollBy({
            left: -300,
            behavior: 'smooth',
        });
    };

    const scrollRight = () => {
        console.log('Scrolling Right');
        sliderRef.current.scrollBy({
            left: 500,
            behavior: 'smooth',
        });
    };


    const settingList = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/order/get-setting`, {
                withCredentials: true,
                headers: { "Content-Type": "application/json","Authorization": `Bearer ${token}` },
            });

            const setting = response.data.result?.flatMap(item => item.settingAll) || [];


            setSetting(setting);
        } catch (err) {
            console.error("Error fetching orders:", err);
        }
    };


    useEffect(() => {
        settingList();
    }, []);
    const images = setting.map((item) => item.s3_img);

    const handleSubmit = async () => {
        if (!selectedOption || !selectedId || !selectedId1) {
            if (!selectedOption) setPayment("Please Select Payment Method");
            if (!selectedId || !selectedId1) setRequred("Required field");
            return;
        }


        setIsLoading(true);

        try {
            navigate("/DepositeDetails", { state: { selectedId, selectedOption, selectedId1 } });
            console.log("submit");
        } catch (error) {
            console.error("Navigation failed:", error);
        } finally {
            setIsLoading(false);
        }
    };
    const itemsPerPage = 10;
    const startIndex = (currentPage - 1) * itemsPerPage;
    return (
        <div className="flex justify-center bg-mainColor w-full h-full p-4">

            <div className="grid grid-cols-12 gap-2  border border-pure-greys-300 p-4 rounded-xl w-screen  bg-black h-fit"
                style={{
                    backgroundImage: `url('/assets/images/cricket.svg'), linear-gradient(180deg, rgba(0, 0, 0, 0) 69%, #000000 93.5%)`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}>

                <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-6 xl:col-span-6 p-1  bg-black h-full ">

                    <div className="h-full ">
                        <div className="font-poppins text-lg text-withdrawalbg pl-2">
                            Deposit
                        </div>
                        <div className="flex flex-col items-center bg-gray-800 p-2 rounded-lg w-full ">

                            <div className="relative w-full">
                                <input
                                    type="text"
                                    value={selectedId || ""}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d+$/.test(value) || value === "") {
                                            handleChange(e);
                                        }
                                    }}
                                    placeholder="Enter Amount (INR)"
                                    className="w-full bg-dropdownbg text-white py-2 px-4 rounded-md 
      border border-gray-600 appearance-none focus:outline-none 
      focus:ring-2 focus:ring-gray-500 bg-topbg"
                                />

                                {!selectedId && <p className="text-red m-2 text-xs">{required}</p>}
                                {selectedId !== "" && selectedId <= 0 && (
                                    <p className="text-red m-2 text-xs">Value must be greater than 0</p>
                                )}
                            </div>

                            <div className="relative w-full m-4 ">
                                <select
                                    value={selectedId1}
                                    onChange={handleChangeOption}
                                    className="w-full bg-topbg  text-white py-2 px-4 rounded-md border border-gray-600 appearance-none focus:outline-none mt focus:ring-2 focus:ring-gray-500"
                                >
                                    <option > Select ID
                                    </option>

                                    {gameList?.map((item) => (
                                        <option key={item?.id} value={item?.id}>
                                            {item?.username} || {item?.portalName}

                                        </option>
                                    ))}

                                </select>

                                <div className="absolute top-2.5 right-4 pointer-events-none">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="white"
                                        className="w-4 h-4"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M19 9l-7 7-7-7"
                                        />
                                    </svg>
                                </div>
                                {!selectedId1 && <p className="text-red m-2 text-xs">{required}</p>}

                            </div>

                        </div>

                        <div className="bg-transtioncolorbg">
                            <div className="text-white font-bold ml-6 pt-5"> Choose Payment Method</div>
                            <div className="flex gap-4 p-4">
                                <div
                                    className={`w-96 h-[110px] rounded-lg shadow-lg p-4 flex flex-col justify-center cursor-pointer bg-paytmcolor ${selectedOption === "UPI" ? 'border-4 border-white  ' : 'bg-paytmcolor'}`}
                                    onClick={() => handleSelect("UPI")}
                                >
                                    <div className="text-xl font-semibold text-center">
                                        <img
                                            src={upis}
                                            className="w-24 h-auto mx-auto"
                                        />
                                    </div>
                                </div>

                                <div
                                    className={`w-96 h-[110px] rounded-lg shadow-lg p-4 flex flex-col justify-center cursor-pointer bg-upicolor  ${selectedOption === "IMPS" ? 'border-4 border-white ' : 'bg-upicolor'}`}
                                    onClick={() => handleSelect("IMPS")}
                                >
                                    <div className="text-xl font-semibold text-center">
                                        <img
                                            src={imps}
                                            className="w-24 h-auto mx-auto"
                                        />
                                    </div>
                                </div>

                                <div
                                    className={`w-96 h-[110px] rounded-lg shadow-lg p-4 flex flex-col justify-center cursor-pointer bg-qrcolor ${selectedOption === "QR" ? 'border-4 border-white' : 'bg-qrcolor'}`}
                                    onClick={() => handleSelect("QR")}
                                >
                                    <div className="text-xs sm:text-md font-semibold text-center">
                                        PAY BY QR
                                        <img src="assets/images/scaner.svg" className="w-6 sm:w-10 h-auto mx-auto mt-1" />
                                    </div>

                                </div>
                            </div>
                            {!selectedOption && <p className="text-red ml-5 text-xs">{payment}</p>}

                            <div className="flex justify-center pb-2">

                                <button
                                    className={`bg-submitbg text-black py-2 px-4 rounded 
                ${isLoading ? "opacity-50 cursor-not-allowed" : ""}`}
                                    onClick={handleSubmit}
                                    disabled={isLoading}
                                >
                                    {isLoading ? "Submitting..." : "Submit"}
                                </button>

                            </div>
                        </div>

                        <div className="bg-transtioncolorbg mt-6 p-6 rounded-md">
                            <ol className="list-decimal space-y-2 text-white font-poppins text-sm">
                                <div className="text-withdrawalbg">Instructions / निर्देश:</div>
                                <li>
                                    Deposit money only in the below available accounts to get the fastest credits and avoid possible delays.
                                    <br /> केवल नीचे दिए गए उपलब्ध खातों में ही पैसे जमा करें ताकि आपको सबसे तेज़ क्रेडिट मिले और संभावित देरी से बचा जा सके।
                                </li>
                                <li>
                                    Deposit mode after the account removal from the site will be responsible by customer only.
                                    <br /> खाता साइट से हटाए जाने के बाद जमा करने का तरीका ग्राहक की जिम्मेदारी होगी।
                                </li>
                                <li>
                                    After deposit, add your UTR and amount to receive balance.
                                    <br /> जमा करने के बाद, अपना UTR और राशि जोड़ें ताकि बैलेंस प्राप्त किया जा सके।
                                </li>
                            </ol>
                        </div>


                    </div>
                </div>
                <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-6 xl:col-span-6 p-2 h-auto bg-transtioncolorbg">

                    <div className="w-full">
                        <div className="flex justify-between m-3 text-white gap-x-2">
                            <div>Deposit List</div>
                            <div className="flex gap-2">
                                <button onClick={scrollLeft}>
                                    <FaArrowLeftLong />
                                </button>
                                <button onClick={scrollRight}>
                                    <FaArrowRightLong />
                                </button>
                                <button
                                    onClick={() => window.location.reload()}
                                    className="bg-blue-500 text-white px-3 ml-2 py-1 rounded-md shadow-md hover:bg-blue-600 transition flex items-center gap-2"
                                >
                                    <FaSyncAlt />

                                    Refresh
                                </button>

                            </div>
                        </div>

                        <div className="relative bg-blackbackgound w-full h-full p-2 rounded-b-lg">
                            <div
                                ref={sliderRef}
                                className="w-full overflow-x-auto bg-blackbackgound rounded-b-lg"
                                style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
                            >
                                <div className="min-w-max">
                                    <div className="grid grid-cols-8 bg-topbg text-white font-bold px-4 py-2 rounded-lg text-sm gap-2">
                                        {['S.NO.', 'UTR', 'Mode', 'Status', 'Amount(INR)', 'Remark', 'Created At', 'File'].map((header, i) => (
                                            <div key={i} className="text-center bg-withdrawalbg text-black font-poppins border px-3 py-1 rounded-3xl truncate">
                                                {header}
                                            </div>
                                        ))}
                                    </div>

                                    <div>
                                        {
                                            depositList && depositList.length > 0 ? (
                                                depositList.map((transaction, index) => (
                                                    <div key={index} className="grid grid-cols-8 text-white border-b border-gray-700 px-4 py-3 text-sm gap-2">
                                                        <div className="text-center truncate">{startIndex + index + 1}.</div>
                                                        <div className="text-center truncate">{transaction.utr || "N/A"}</div>
                                                        <div className="text-center truncate">{transaction.mode || "N/A"}</div>
                                                        <div
                                                            className={`flex items-center justify-center gap-x-1 px-3 py-1 rounded-md truncate ${transaction.status === "SUCCESS" ? "bg-darkGreen" : transaction.status === "REJECTED" ? "bg-redbg" : "bg-liteBlue"
                                                                }`}
                                                        >
                                                            {transaction.status}
                                                        </div>
                                                        <div className="text-center truncate">{transaction.amount || "N/A"}</div>
                                                        <div className="text-center truncate">{transaction.remark || "N/A"}</div>
                                                        <div className="text-center truncate">{(formatDateTime(transaction.createdAt))}</div>
                                                        <div className="text-center truncate flex items-center justify-center space-x-2">
                                                            <a href={`${images}${transaction.txnFile}`} target="_blank" rel="noopener noreferrer">
                                                                <BsEye className="w-5 h-5 text-gray-600 hover:text-blue-500 cursor-pointer" />
                                                            </a>
                                                        </div>

                                                    </div>
                                                ))) : <p className="text-white justify-center flex text-center p-2">No data found</p>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Pagination */}
                        {
                            depositList && depositList.length > 0 ? (
                                <div className="w-full flex justify-center mt-4">
                                    <div className="flex items-center gap-3">
                                        <button
                                            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                                            disabled={currentPage === 1}
                                            className={`w-10 h-10 flex items-center justify-center bg-gray-700 text-white rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-500"
                                                }`}
                                        >
                                            <FaArrowLeftLong />
                                        </button>

                                        <span className="text-white text-sm">
                                            Page {currentPage} of {totalPages}
                                        </span>

                                        <button
                                            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                                            disabled={currentPage == totalPages}
                                            className={`w-10 h-10 flex items-center justify-center bg-gray-700 text-white rounded 
                                                ${currentPage == totalPages ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-500"}`}
                                        >
                                            <FaArrowRightLong />
                                        </button>
                                    </div>
                                </div>) : null}

                    </div>

                </div>            </div>


            <PopupWithBlur isPopupOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen} />
            <WithDrawPop isPopupOpen={widthPopOpen} setIsPopupOpen={setwidthPopupOpen} />
            <PopUpLogin isPopupOpen={loginPopOpen} setIsPopupOpen={setLoginPopupOpen} />

        </div>


    );
};

export default Deposites;
