import { BiCricketBall, BiFootball, BiTennisBall } from "react-icons/bi";
import {
  GiAmericanFootballHelmet,
  GiFootTrip,
  GiHockey,
  GiHorseHead,
  GiPoolTableCorner,
  GiShuttlecock,
} from "react-icons/gi";
import {
  FaBasketballBall,
  FaGamepad,
  FaHorseHead,
  FaMotorcycle,
  FaTableTennis,
  FaVolleyballBall,
} from "react-icons/fa";
import { PiSoccerBallFill, PiBoxingGloveFill } from "react-icons/pi";
import { MdSportsMartialArts, MdOutlineSportsHandball } from "react-icons/md";
import { FaPeopleLine } from "react-icons/fa6";
import { FaPeoplePulling } from "react-icons/fa6";

const cricket = "/assets/logo/cricket.svg";
const tennis = "/assets/logo/tennis.svg";
const football = "/assets/logo/football.svg";
const horse = "/assets/logo/horse.svg";
const horseRunn = "/assets/logo/horseRunn.svg";
const image1 = "/assets/images/image.png";
const image2 = "/assets/images/image7.png";
const misi = "/assets/images/misi.png";
const upiImage = "/assets/images/upiImage.png";
const OR = "/assets/images/upiOr.png";
const download = "/assets/images/download.png";

export const LABELS = [
  { label: "Casino", id: 1 },
  { label: "Sport", id: 2 },
];

export const MENU_OPTIONS = [
  {
    label: "Cricket",
    icon: <img src={cricket} alt="cricket" />,
    path: "/HomeAfterLogin",
  },
  {
    label: "Create New Id",
    icon: <img src={football} alt="soccer" />,
    path: "/CreateNewId",
  },
  { label: "Tennis", icon: <img src={tennis} alt="tennis" />, path: "" },
  {
    label: "Horse Racing",
    icon: <img src={horseRunn} alt="horse racing" />,
    path: "",
  },
  { label: "Horse Race", icon: <img src={horse} alt="horse" />, path: "" },
  {
    label: "download",
    icon: <img src={download} alt="cricket" width={30} />,
    path: "https://ssbook2.s3.ap-south-1.amazonaws.com/banner/ssbook_app.apk",
  },
];

export const transactions = [
  {
    id: "1234565645",
    amount: 12302,
    status: "Approved",
    date: "12/04/24",
    reason: "Investment",
  },
  {
    id: "5666515655",
    amount: 458967,
    status: "Rejected",
    date: "12/04/24",
    reason: "Investment",
  },
  {
    id: "7891234567",
    amount: 12302,
    status: "Pending",
    date: "12/04/24",
    reason: "Investment",
  },
];

export const portals = [
  { id: 1, image: image1, name: "classicexch99.comlfsjfksnfk" },
  { id: 2, image: image2, name: "jaigo7.com" },
  { id: 3, image: image1, name: "classicexch99.com" },
  { id: 4, image: image2, name: "jaigo7.com" },
  { id: 5, image: image1, name: "classicexch99.com" },
  { id: 6, image: image2, name: "jaigo7.com" },
];

export const categoryData = [
  { id: 1, label: "MrBeanexch", path: "https://www.mrbeanexch.com/" },
  { id: 2, label: "Classicexch99", path: "https://classicexch99.com/" },
  { id: 3, label: "Jaigo 7", path: "https://www.jaigo7.com/" },
  { id: 4, label: "SSBook 365", path: "https://Ssbook365.com/" },
];

export const images = [
  "https://ssbook2.s3.ap-south-1.amazonaws.com/banner/mb_1.png",
  "https://ssbook2.s3.ap-south-1.amazonaws.com/banner/mb_2.png",
  "https://ssbook2.s3.ap-south-1.amazonaws.com/banner/mb_3.png",
];
export const moreImage = [
  "https://ssbook2.s3.ap-south-1.amazonaws.com/banner/av_1.png",
  "https://ssbook2.s3.ap-south-1.amazonaws.com/banner/av_2.png",
  "https://ssbook2.s3.ap-south-1.amazonaws.com/banner/av_3.png",
];

export const data = [
  { id: 1, imgSrc: misi, label: "IMPS" },
  { id: 2, imgSrc: upiImage, label: "UPI" },
  { id: 3, imgSrc: OR, label: "OR" },
];

export const casinoGames = [
  "All Casino",
  "Roulette",
  "Teenpatti",
  "Poker",
  "Baccarat",
  "Dragon Tiger",
  "32 Cards",
  "Andar Bahar",
  "Lucky 7",
  "3 Card Judgement",
  "Casino War",
  "Worli",
  "Sports",
  "Bollywood",
  "Lottery",
  "Queen",
  "Race",
  "Others",
];

export const sportsMenu = [
  { label: "Cricket", icon: <BiCricketBall size={20} /> },
  { label: "Football", icon: <BiFootball size={20} /> },
  { label: "Tennis", icon: <BiTennisBall size={20} /> },
  { label: "Esoccer", icon: <PiSoccerBallFill size={20} /> },
  { label: "Horse Racing", icon: <GiHorseHead size={20} /> },
  { label: "Greyhound Racing", icon: <FaHorseHead size={20} /> },
  { label: "Table Tennis", icon: <FaTableTennis size={20} /> },
  { label: "Basketball", icon: <FaBasketballBall size={20} /> },
  { label: "Boxing", icon: <PiBoxingGloveFill size={20} /> },
  { label: "Mixed Martial Arts", icon: <MdSportsMartialArts size={20} /> },
  { label: "American Football", icon: <GiAmericanFootballHelmet size={20} /> },
  { label: "Volleyball", icon: <FaVolleyballBall size={20} /> },
  { label: "Badminton", icon: <GiShuttlecock size={20} /> },
  { label: "Snooker", icon: <GiPoolTableCorner size={20} /> },
  { label: "Ice Hockey", icon: <GiHockey size={20} /> },
  { label: "E Games", icon: <FaGamepad size={20} /> },
  { label: "Politics", icon: <FaPeopleLine size={20} /> },
  { label: "Futsal", icon: <GiFootTrip size={20} /> },
  { label: "Handball", icon: <MdOutlineSportsHandball size={20} /> },
  { label: "Motor Sports", icon: <FaMotorcycle size={20} /> },
  { label: "Kabaddi", icon: <FaPeoplePulling size={20} /> },
];

export const socialIcons = [
  {
    name: "gmail",
    type: "image",
    src: "/assets/socialIcons/icons8-gmail.svg",
    url: "mailto:Support@ssbook.org.in",
  },
  {
    name: "instagram",
    type: "image",
    src: "/assets/socialIcons/icons8-instagram.svg",
    url: "https://www.instagram.com/saawaliyasyndicate/profilecard/?igsh=MThscDZ4b25pMHV4Ng==",
  },
  {
    name: "youtube",
    type: "image",
    src: "/assets/socialIcons/icons8-youtube.svg",
    url: "https://youtube.com/@saawaliyasyndicatebovok?si=gIpJfGXvdD2fezjF",
  },
  // { name: "twitter", type: "image", src: "/assets/socialIcons/icons8-twitter.svg", url: "#" },
  {
    name: "telegram",
    type: "image",
    src: "/assets/socialIcons/icons8-telegram.svg",
    url: "https://t.me/saawaliyasyndicatebook",
  },
  {
    name: "threads",
    type: "image",
    src: "/assets/socialIcons/icons8-threads.svg",
    url: "https://www.threads.net/@saawaliyasyndicate",
  },
  {
    name: "facebook",
    type: "image",
    src: "/assets/socialIcons/icons8-facebook.svg",
    url: "https://www.facebook.com/profile.php?id=61565272599001&mibextid=ZbWKwL",
  },
];

export const paymentMethods = [
  {
    name: "googlePay",
    type: "image",
    src: "/assets/socialIcons/googlePay.png",
  },
  { name: "phonePe", type: "image", src: "/assets/socialIcons/phonePe.png" },
  { name: "cred", type: "image", src: "/assets/socialIcons/cred1.png" },
  {
    name: "whatsAppPay",
    type: "image",
    src: "/assets/socialIcons/whatsappPay.png",
  },
  { name: "paytm", type: "image", src: "/assets/socialIcons/paytm.png" },
  { name: "bhartPay", type: "image", src: "/assets/socialIcons/BharatPe.png" },
  {
    name: "netBanking",
    type: "image",
    src: "/assets/socialIcons/netBanking.png",
  },
];

export const companyLinks = [
  { name: "Privacy Policy", url: "/PrivacyPolicy" },
  { name: "About Us", url: "/About" },
  { name: "Rules & Regulations", url: "/RulesAndRegulations" },
  { name: "Terms & Conditions", url: "/TearmAndConditions" },
  {
    name: "Gambling can be addictive, please play responsibly",
    url: "/GameAdition",
  },
  {
    name: "KYC and Privacy Policy",
    url: "/KYC",
  },
  { name: "Responsible Gaming", url: "/ResponsibleGaming" },
];

export const gameLinks = [
  { name: "Online Sports Betting", href: "/" },
  { name: "Card Game", href: "/" },
  { name: "Cricket Betting", href: "/" },
  { name: "IPL Betting", href: "/" },
  { name: "Soccer Betting", href: "/" },
  { name: "Tennis Betting", href: "/" },
  { name: "Live Casino Betting", href: "/" },
  { name: "Online Betting App", href: "/" },
];
