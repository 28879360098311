export default function PrivacyPolicy() {
  const logo = "/assets/logo/logobg.svg";
    return (
      <div className="bg-mainColor min-h-screen flex justify-center ">
        <div className=" text-white  rounded-lg shadow-lg">
        <div className="bg-mainColor w-full py-2 shadow-md flex flex-col justify-start fixed">
          {/* Logo Section */}
          <div className="flex items-center gap-2 text-3xl font-bold">
          <img src={logo} alt="Company Logo" />
          </div>

          {/* Privacy Policy Header */}
          <div className="bg-black text-white text-lg font-bold p-2 mt-4">
            Privacy Policy
          </div>
          </div>
         <div className="mt-36 p-4 bg-richblack-700">  {/* Privacy Policy Content */}
          <p className=" text-md leading-6 font-poppins mt-6">
            At SSBook - Saawaliya Syndicate, we are committed to protecting your
            privacy and ensuring a secure gaming experience. As a fully licensed
            and regulated betting and gaming platform under the Curaçao eGaming
            Authority (License No. 115/JAZ), we adhere to strict data protection
            and security standards. This Privacy Policy explains how we collect,
            use, store, and protect your personal and financial information when
            you use our platform.
          </p>
  
          {/* Sections */}
          <h2 className="text-lg font-bold mt-6">1. Information We Collect</h2>
          <ul className="list-disc pl-5 mt-2 text-sm leading-6">
            <li><strong>Personal Information:</strong> Name, date of birth, address, email, contact number, and identity verification details.</li>
            <li><strong>Financial Data:</strong> Payment details, transaction history, and withdrawal information.</li>
            <li><strong>Gaming & Betting Activity:</strong> Game preferences, betting history, wins/losses, and time spent on the platform.</li>
            <li><strong>Technical Data:</strong> IP address, device information, browser type, and login activity.</li>
          </ul>
  
          <h2 className="text-lg font-bold mt-6">2. How We Use Your Information</h2>
          <ul className="list-disc pl-5 mt-2 text-sm leading-6">
            <li>Account registration, verification, and security checks.</li>
            <li>Processing deposits, withdrawals, and payments securely.</li>
            <li>Providing a personalized gaming experience and customer support.</li>
            <li>Ensuring compliance with regulatory and anti-money laundering (AML) policies.</li>
            <li>Preventing fraud, unauthorized access, and suspicious activities.</li>
            <li>Sending promotional offers, updates, and notifications (only with user consent).</li>
          </ul>
  
          <h2 className="text-lg font-bold mt-6">3. Data Protection & Security</h2>
          <p className="mt-2 text-sm leading-6">
            SSBook employs advanced encryption technologies, firewalls, and secure payment gateways to
            safeguard your data from unauthorized access, breaches, or misuse. We continuously monitor
            transactions and activities to detect and prevent fraudulent behavior.
          </p>
  
          <h2 className="text-lg font-bold mt-6">4. Third-Party Sharing & Compliance</h2>
          <p className="mt-2 text-sm leading-6">
            We do not sell or rent your personal information to third parties. However, we may share data with:
          </p>
          <ul className="list-disc pl-5 mt-2 text-sm leading-6">
            <li>Regulatory authorities and law enforcement agencies when required by law.</li>
            <li>Payment processors and financial institutions for secure transactions.</li>
            <li>Fraud detection and anti-money laundering partners to ensure fair play.</li>
          </ul>
  
          <h2 className="text-lg font-bold mt-6">5. Cookies & Tracking Technologies</h2>
          <p className="mt-2 text-sm leading-6">
            SSBook uses cookies and analytics tools to enhance user experience, track website
            performance, and improve services. You can manage cookie preferences through your browser
            settings.
          </p>
  
          <h2 className="text-lg font-bold mt-6">6. Responsible Gaming & Age Restriction</h2>
          <p className="mt-2 text-sm leading-6">
            SSBook promotes responsible gaming and strictly prohibits users under the age of 18 from
            accessing our platform. We encourage self-exclusion options and gaming limits for responsible
            betting.
          </p>
  
          <h2 className="text-lg font-bold mt-6">7. User Rights & Data Access</h2>
          <ul className="list-disc pl-5 mt-2 text-sm leading-6">
            <li>Access, update, or delete their personal information.</li>
            <li>Opt out of marketing communications.</li>
            <li>Request information about how their data is stored and used.</li>
          </ul>
  
          <h2 className="text-lg font-bold mt-6">8. Policy Updates & Contact Information</h2>
          <p className="mt-2 text-sm leading-6">
            We may update this Privacy Policy periodically to comply with regulations and improve security
            measures. For any concerns or inquiries, contact our support team through our website.
          </p>
  
          <p className="mt-4 text-sm leading-6 font-bold">
            By using SSBook - Saawaliya Syndicate, you agree to this Privacy Policy and our commitment to
            ensuring a safe, fair, and secure betting environment.
          </p>

    </div>
        
        </div>
      </div>
    );
  }
  