import React, { useState } from "react";
import { RiFileCopyFill } from "react-icons/ri";
import { BiError } from "react-icons/bi";
import { ImCross } from "react-icons/im";

const PopUpLogin = ({ isPopupOpen, setIsPopupOpen }) => {
  const [withdrawalId] = useState("12345678905665664");

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const getMaskedWithdrawalId = (id) => {
    const firstPart = id.slice(0, 3);
    const maskedPart = "*".repeat(id.length - 3);
    return firstPart + maskedPart;
  };

  const handleCopy = (e) => {
    e.stopPropagation();  // Prevent click from closing the modal
    navigator.clipboard.writeText(withdrawalId).then(() => {
      alert("Withdrawal ID copied to clipboard!");
    });
  };

  return (
    <div>
      {/* Popup Modal */}
      {isPopupOpen && (
        <div
          className="fixed inset-0 flex justify-center items-center z-50 bg-opacity-80 backdrop-blur-sm bg-black"
          onClick={handleClosePopup}
        >
          {/* Popup Content */}
          <div
            className="w-[800px] h-[400px] rounded-md shadow-lg bg-white"
            onClick={(e) => e.stopPropagation()} // Prevent click on the modal content from closing it
          >
            {/* Modal Header */}
            <div className="flex items-center justify-between text-lg font-bold text-center font-poppins text-white bg-timebg p-2">
              <h2 className="flex-grow text-center">WITHDRAW</h2>
              <button onClick={handleClosePopup}>
                <ImCross  />
              </button>
            </div>

            {/* Modal Body */}
            <div className="justify-center flex items-center mt-20">
              <div className="w-full max-w-[250px]">
                <label
                  htmlFor="withdrawalId"
                  className="block text-s uppercase font-bold text-gray-700"
                >
                  New ID:
                </label>
                <div className="flex items-center mt-2 relative">
                  <input
                    type="text"
                    id="withdrawalId"
                    value={getMaskedWithdrawalId(withdrawalId)}
                    readOnly
                    className="block w-full px-6 py-4 text-white bg-transtioncolor rounded-md shadow-sm focus:outline-none sm:text-sm"
                  />
                  <button
                    onClick={handleCopy}
                    className="text-timebg absolute left-52 text-white rounded-md focus:outline-none"
                  >
                    <RiFileCopyFill size={24} />
                  </button>
                </div>
              </div>
            </div>
            <div className="justify-center flex items-center mt-2 ">
              <div className="w-full max-w-[250px]">
                <label
                  htmlFor="withdrawalId"
                  className="block text-s uppercase font-bold text-gray-700"
                >
                  Password :
                </label>
                <div className="flex items-center mt-2 relative">
                  <input
                    type="text"
                    id="withdrawalId"
                    value={getMaskedWithdrawalId(withdrawalId)}
                    readOnly
                    className="block w-full px-6 py-4 text-white bg-transtioncolor rounded-md shadow-sm focus:outline-none sm:text-sm"
                  />
                  <button
                    onClick={handleCopy}
                    className="text-timebg absolute left-52 text-white rounded-md focus:outline-none"
                  >
                    <RiFileCopyFill size={24} />
                  </button>
                </div>
              </div>
            </div>
            <div
              className="w-[650px] h-[30px] bg-errorbg mx-20 my-10 text-center text-red flex items-center justify-center rounded-md"
              onClick={handleClosePopup}
            >
              <BiError /> Please save these credentials for your reference and Do not share them with anyone.
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PopUpLogin;