export default function AboutUs() {
  const logo = "/assets/logo/logobg.svg";
  return (
    <div className="bg-mainColor min-h-screen flex justify-center">
      <div className=" text-white rounded-lg shadow-lg">
        <div className="bg-mainColor w-full py-2 shadow-md flex flex-col justify-start fixed">
          {/* Header Section */}
          <div className="flex items-center gap-2 text-3xl font-bold">
            <img src={logo} alt="Company Logo" />
          </div>

          {/* About Us Header */}
          <div className="bg-black text-white text-lg font-bold p-2 mt-4">
            About Us
          </div>
        </div>
        <div className="mt-36 p-4 bg-richblack-700  ">
          {/* About Us Content */}
          <p className="mt-6 text-md leading-6 font-poppins ">
            Welcome to <strong>SSBook - Saawaliya Syndicate</strong>, a premier
            online gaming platform dedicated to delivering an exceptional and
            secure gaming experience. As a fully licensed and regulated entity
            under the{" "}
            <strong>Curaçao eGaming Authority (License No. 115/JAZ)</strong>, we
            uphold the highest standards of integrity, transparency, and
            responsible gaming.
          </p>

          <h2 className="text-lg font-bold mt-6">Our Platform</h2>
          <p className="mt-2 text-sm leading-6">
            At SSBook, we offer a dynamic and engaging platform for{" "}
            <strong>sports predictions</strong>, <strong>fantasy gaming</strong>
            , and various interactive betting opportunities. Our
            state-of-the-art technology ensures a seamless, fair, and thrilling
            experience for our users, backed by robust security measures to
            protect your data and transactions.
          </p>

          <h2 className="text-lg font-bold mt-6">Our Commitment</h2>
          <p className="mt-2 text-sm leading-6">
            With a strong commitment to <strong>fair play</strong>,{" "}
            <strong>customer satisfaction</strong>, and{" "}
            <strong>innovation</strong>, SSBook - Saawaliya Syndicate
            continuously strives to enhance the gaming landscape.
          </p>

          <p className="mt-4 text-sm leading-6 font-bold">
            Join us today and be a part of an exciting world where strategy
            meets entertainment!
          </p>
        </div>
      </div>
    </div>
  );
}
