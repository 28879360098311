import React, { useEffect, useRef, useState } from "react";
import { ImCross } from "react-icons/im";
import { LuAlarmClock } from "react-icons/lu";
import "../Spinner/Spinner.css";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../Services/config";
const CreateNewIdTimer = ({ isPopupOpen, setIsPopupOpen,successMessage}) => {
    const [setting, setSetting] = useState([]);
    const [timeLeft, setTimeLeft] = useState(null);
    const hasToastFired = useRef(false);
    const navigate = useNavigate()
    const settingList = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/order/get-setting`, {
                withCredentials: true,
                headers: { "Content-Type": "application/json" },
            });

            const settingData = response.data.result?.flatMap((item) => item.settingAll) || [];
            setSetting(settingData);
        } catch (err) {
            console.error("Error fetching settings:", err);
        }
    };

    useEffect(() => {
        settingList();
    }, []);

    useEffect(() => {
        if (isPopupOpen && setting.length > 0) {
            const game_id_timer = setting[0]?.game_id_timer;

            if (game_id_timer) {
                const totalSeconds = game_id_timer * 60;
                setTimeLeft(totalSeconds);
                hasToastFired.current = false; 
                const timer = setInterval(() => {
                    setTimeLeft((prev) => {
                        if (prev <= 1) {
                            clearInterval(timer);
                            setIsPopupOpen(false);
                            if (!hasToastFired.current) {
                                // toast.success(successMessage);
                                hasToastFired.current = true; 
                              }
                            return null;
                        }
                        return prev - 1;
                    });
                }, 1000);

                return () => clearInterval(timer);
            }
        }
    }, [isPopupOpen, setting]);


    return (
        <div className="max-w-[500px]">
            {isPopupOpen && timeLeft !== null && (
                <div className="fixed inset-0 flex items-center justify-center z-50 ">
                    <div className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-sm"></div>
                    <div className="relative bg-white w-120 rounded-md shadow-lg">
                        <div className="flex text-lg font-bold text-center text-white bg-timebg justify-center p-2 gap-x-2">
                            <h2>Timer</h2>
                            <LuAlarmClock size={28} />
                            <div className="absolute right-2 top-2 cursor-pointer">
                                <ImCross onClick={() => setIsPopupOpen(false)} />
                            </div>
                        </div>

                        <ul className="text-sm text-red font-poppins space-y-2 p-5">
  <li>1. The game ID you create must be linked to your registered account.  
      <br />आपके द्वारा बनाया गया गेम आईडी आपके पंजीकृत खाते से जुड़ा होना चाहिए।</li>

  <li>2. Ensure that your account details are accurate before creating a new game ID.  
      <br />नई गेम आईडी बनाने से पहले सुनिश्चित करें कि आपके खाता विवरण सही हैं।</li>

  <li>3. Once a game ID is created, changes cannot be made without contacting support.  
      <br />एक बार गेम आईडी बन जाने के बाद, बिना सपोर्ट से संपर्क किए बदलाव नहीं किए जा सकते।</li>

  <li>4. Please complete your game ID creation process before the timer expires.  
      <br />कृपया टाइमर समाप्त होने से पहले अपना गेम आईडी निर्माण प्रक्रिया पूरी करें।</li>
</ul>


                        <div className="text-center mt-4">
                            <button className="bg-caribbeangreen-200 text-white px-8 mb-4 py-2 rounded-md">
                                {Math.floor(timeLeft / 60)} Min : {timeLeft % 60} Sec
                            </button>
                        </div>

                        <div className="flex justify-center items-center mt-4 mb-8">
                            <div className="spinner"></div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CreateNewIdTimer;
