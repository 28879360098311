import React from 'react';

import { data } from "../../Data/Data";
function Upi() {
    const sportImage = "/assets/images/bolltennis.svg"
    const adidas = "/assets/images/adidas.svg"

    return (
        <div className="flex justify-center bg-mainColor w-full h-full p-4">
            <div className="border border-pure-greys-300 p-4 rounded-xl w-full max-w-6xl">
                {/* Header Section */}
                <div className="flex justify-start ">
                    <div className="font-poppins text-lg text-withdrawalbg">
                        Select the portal
                    </div>
                </div>
                {/* Card's section  */}
                <div className="bg-blackbackgound w-full p-4">
                    <div className="flex flex-wrap justify-start gap-4">
                        {data.map((item, index) => (
                            <div
                                key={item.id}
                                className="border-3 p-3 space-y-3 rounded-xl bg-borderback w-full sm:w-[200px]"
                            >
                                <div className="bg-brandbackground flex justify-center items-center">
                                    <img
                                        src={item.imgSrc}
                                        alt="brandImage"
                                        className="w-[150px] h-[100px] object-contain"
                                    />
                                </div>
                                <div>
                                    <button className="bg-withdrawalbg px-4 py-3 rounded-lg text-black font-bold font-poppins w-full">
                                        {item.label}
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>



                    <div className="flex flex-wrap gap-4 pt-4">
                        <div className="w-full sm:w-1/3">
                            <label htmlFor="username" className="block text-md font-medium text-white">
                                Username:
                            </label>
                            <input
                                type="text"
                                id="username"
                                placeholder="Enter Your Username"
                                className="mt-1 block w-full px-3 py-3 rounded-md shadow-sm focus:outline-none sm:text-sm"
                            />
                        </div>
                        <div className="w-full sm:w-auto">
                            <button className="uppercase bg-green text-white font-semibold font-poppins rounded-lg py-3 px-6 w-full lg:mt-6 md:mt-6 sm:mt-6 sm:w-auto">
                                Create ID
                            </button>
                        </div>
                    </div>

                </div>

                {/* Username and Button Section */}
                <div
                    className="w-full h-64 mb-3 mt-3 relative"
                    style={{
                        background: `
          linear-gradient(0deg, #D9D9D9, #D9D9D9),
          linear-gradient(180deg, rgba(255, 0, 0, 0.7) 11.49%, rgba(0, 0, 0, 0) 116.06%),
          linear-gradient(180deg, rgba(255, 12, 12, 0.14) 0%, rgba(0, 0, 0, 0) 116.06%)
        `,
                    }}
                >
                    {/* Image Section */}
                    <div className="relative flex items-center justify-center h-full mt-4">
                        <img
                            src={sportImage}
                            alt="tennis"
                            className="w-full h-full object-cover"
                        />

                        {/* Text Section */}
                        <div className="absolute lg:top-6 lg:left-6 flex flex-col  lg:flex-row md:flex-col md:items-center  items-center sm:items-start p-4 sm:pl-10 space-y-4 sm:space-y-0 sm:space-x-16 w-full sm:w-auto">
                            {/* Adidas Logo */}
                            <div>
                                <img src={adidas} alt="adidas" className="w-12 sm:w-16 md:w-20" />
                            </div>

                            {/* Promotional Text */}
                            <div className="text-white font-inter uppercase text-center sm:text-left">
                                <div className="max-w-xs sm:max-w-sm   ">
                                    <p className="text-lg sm:text-xl md:text-2xl font-extrabold  ">
                                        Elevate Your Tennis Game Today
                                    </p>
                                    <p className="text-sm sm:text-base">
                                        Smash the Competition with{" "}
                                        <span className="font-bold">30% discount</span>
                                    </p>
                                </div>

                                {/* Download Button */}
                                <button className="uppercase text-black bg-downloadbtn px-4 py-2 mt-4 font-bold rounded-md">
                                    Download App
                                </button>
                            </div>
                        </div>
                    </div>



                </div>





            </div>
        </div>
    );
}

export default Upi;



