import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../Services/config";
import axios from "axios";
import { useAuth } from "../../Services/AuthContext";
import { images } from "../Data/Data";

const GameGrid = () => {
  const mainCards = new Array(12).fill("/assets/images/game.png");
  const aviatore = new Array(10).fill("/assets/images/aviator.png");
  const link = "https://classicexch99.com/";
  const [bannerBottom, setBannerBottom] = useState([])
  const { token, fetchUserProfile } = useAuth();
  const [setting, setSetting] = useState([]);

  const settingList = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/order/get-setting`, {
        withCredentials: true,
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
      });
      const setting =
        response.data.result?.flatMap((item) => item.settingAll) || [];

      setSetting(setting);
    } catch (err) {
      console.error("Error fetching orders:", err);
    }
  };

  useEffect(() => {
    settingList();
  }, []);
  const image = setting.map((item) => item.s3_img);

  const fetchBannerbottom = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/order/get-home-banner-bottom`, {

        withCredentials: true,
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
      });
      const banner =
        response.data.result?.flatMap((item) => item.banner) || [];
      setBannerBottom(banner);
    } catch (err) {
      console.error("Error fetching orders:", err);
    }
  };
  useEffect(() => {
    fetchBannerbottom();
  }, []);

  const L2 = bannerBottom.filter(b => b.type === "L2");
  const L1 = bannerBottom.filter(b => b.type === "L1")

  return (
    <div className="min-h-screen bg-[#0d0415] flex flex-col items-center p-4">
      <div className="w-full max-w-8xl">
        <h1 className="text-white text-lg font-bold bg-[#083B3E] p-2 pl-3 rounded-md mb-4 text-start">
          GAME
        </h1>
        <div className="grid grid-cols-3 md:grid-cols-8 gap-4">
  {L1.map((card, index) => (
    <a key={index} href={card.link} target="_blank" rel="noopener noreferrer">
      <div className="relative bg-black rounded-lg  overflow-hidden w-full h-full border border-[#6B9EA1]">
        <div className="relative flex justify-center items-center w-full h-24 md:h-40 ">
          <img
            src={`${image}${card.img}`}
            alt={`Card ${index}`}
            className="w-full h-full object-cover rounded-md"
          />
        </div>
      </div>
    </a>
  ))}
</div>





      </div>

      <div className="mt-6 w-full mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
          {L2.map((card, index) => (
            <a key={index} href={card.link} target="_blank" rel="noopener noreferrer">
              <div className="bg-[#6B9EA1] rounded-lg overflow-hidden p-0.5 w-full sm:h-[230px] lg:h-[250px] flex justify-center items-center">
                <img
                  src={`${image}${card.img}`}
                  alt={`Aviator Card ${index}`}
                  className="w-full h-full overflow-hidden  rounded-md "
                />
              </div>
            </a>
          ))}
        </div>
      </div>



    </div>
  );
};

export default GameGrid;
