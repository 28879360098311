import React, { useEffect, useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import { motion, AnimatePresence } from "framer-motion";
import { MENU_OPTIONS, LABELS, sportsMenu, casinoGames } from "../Data/Data"
import { Link } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { useAuth } from "../../Services/AuthContext";
import { BsDot } from "react-icons/bs";
import { DiAndroid } from "react-icons/di";
function SideBar({ isSidebarOpen, setIsSidebarOpen }) {
  const logo = "/assets/logo/logobg.svg"
  const { user } = useAuth()
  const [category, setCategory] = useState({ txt: "Casino", activeId: 1 });
  const download = "/assets/images/download.png";

  const sidebarVariants = {
    open: { x: 0, opacity: 1, transition: { type: "spring", stiffness: 75 } },
    closed: { x: "-100%", opacity: 0, transition: { type: "spring", stiffness: 75 } },
  };
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsSidebarOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <AnimatePresence>
        {isSidebarOpen && (
          <motion.div
            className="bg-mainColor w-[300px] h-screen p-4 fixed top-0 left-0 z-40 shadow-lg overflow-y-auto scrollable-element flex flex-col justify-between"
            initial="closed"
            animate="open"
            exit="closed"
            variants={sidebarVariants}
          >
            <div>
              <div className="flex justify-between items-center">
                <Link to="/HomeAfterLogin" >
                  <img src={logo} alt="logo" />
                </Link>
                <button className="text-white" onClick={() => setIsSidebarOpen(false)}>
                  <IoArrowBack size={24} className="border-l border-richblue-100" />
                </button>
              </div>
              <div>
                <motion.div
                  className="mx-auto flex max-w-fit items-center gap-2 rounded-full border border-gray-300 p-1"
                  style={{
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/assets/images/sidetab.jpg')`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >  {LABELS.map((item) => (
                  <div
                    key={item.id}
                    style={{
                      ...(category.activeId === item.id
                        ? {
                          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/assets/images/sidetab.jpg')`,
                          color: "white",
                        }
                        : {
                        }),
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                    onClick={() => setCategory({ txt: item.label, activeId: item.id })}
                    className={`relative z-[1] cursor-pointer rounded-full bg-scorePlayerbg px-8 py-4 text-center text-md font-semibold 
                      ${category.activeId === item.id ? "text-black" : "text-white"}`}
                  >
                    {category.activeId === item.id && (
                      <motion.div
                        layoutId="activeOptionBackground"
                        className="absolute inset-0 z-[-1] rounded-full bg-[#15b3e191] bg-cover bg-center"
                        transition={{ type: "spring", stiffness: 300, damping: 30 }}
                      />
                    )}
                    {item.label}
                  </div>
                ))}
                </motion.div>
              </div>

              {/* Search Bar */}
              <div className="flex items-center justify-center pt-2">
              <Link
                  to={`https://ssbook2.s3.ap-south-1.amazonaws.com/banner/ssbook_app.apk`}
                >
                <button className="flex items-center w-[230px] justify-center gap-2 bg-gradient-to-r from-blue-600 to-blue-400 hover:bg-blue-700 transition-colors rounded-full p-4 shadow-lg hover:shadow-xl border border-white/20 transform hover:scale-105 group">
                  <div className="text-white flex items-center gap-3">
                    <DiAndroid
                      size={32}
                      className="text-white group-hover:text-blue-100 transition-colors"
                    />
                    <div className="text-left">
                      <p className="text-sm font-semibold tracking-wide">
                        Download
                      </p>
                      <p className="text-xs font-medium opacity-90">
                        The Application Now
                      </p>
                    </div>
                  </div>
                </button>
                </Link>
              </div>


              <div>
                {category.txt === "Sport" &&
                  sportsMenu.map((menu) => (
                    <li key={menu.path}>
                      <Link
                        to={menu.path}
                        onClick={() => setIsSidebarOpen(false)}
                        className="flex items-center gap-x-2 hover:bg-gray-700 px-2 rounded text-white"
                      >
                        {menu.icon}
                        {menu.label}
                      </Link>
                    </li>
                  ))}
              </div>

              <div>
                {
                  category.txt === "Casino" &&
                  casinoGames.map((name, index) => (
                    <li key={index}>
                      <button
                        onClick={() => setIsSidebarOpen(false)}
                        className="block w-full text-left hover:bg-gray-700 px-2  rounded text-white"
                      >
                        <div className="flex  items-center">
                          <BsDot size={25} />
                          {name}
                        </div>
                      </button>
                    </li>
                  ))}
            
              </div>
            </div>

            {user ? (
              <div className="flex items-center justify-between gap-3 border-t-2  border-pure-greys-300 p-4 w-full mt-1">
                <div className="flex items-center gap-3">
                  <img
                    src="/assets/images/1.png"
                    alt="User Avatar"
                    className="w-10 h-10 rounded-full border-2 border-white"
                  />
                  <div>
                    <p className="text-white font-medium text-md">{user?.username}</p>
                  </div>
                </div>
                {/* <button className="text-white hover:text-white transition">
      <FiSettings size={20} />
    </button> */}
              </div>
            ) : null}  

          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

export default SideBar;
