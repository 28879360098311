export default function TermsAndConditions() {
  const logo = "/assets/logo/logobg.svg";
    return (
      <div className="bg-mainColor min-h-screen flex justify-center ">
        <div className="bg-gray-900 text-white  rounded-lg shadow-lg">
          {/* Header Section */}
          <div className="bg-mainColor w-full py-2 shadow-md flex flex-col justify-start fixed">
          <div className="flex items-center gap-2 text-3xl font-bold">
          <img src={logo} alt="Company Logo" />
          </div>
          <div className="bg-black text-white text-lg font-bold p-2 mt-2">
            Terms & Conditions
          </div> 
      </div>

            <div className="bg-richblack-700 text-white text-lg font-bold p-2 mt-36">    
          {/* Terms & Conditions Content */}
          <h2 className="text-lg font-bold mt-6">1. Introduction</h2>
          <p className="mt-2 text-sm leading-6">
            SSBook - Saawaliya Syndicate is a fully licensed and regulated online gaming and
            betting platform, operating under the <strong>Curaçao eGaming Authority (License No. 115/JAZ)</strong>.
          </p>
  
          <h2 className="text-lg font-bold mt-6">2. Eligibility & User Agreement</h2>
          <ul className="list-disc list-inside mt-2 text-sm leading-6">
            <li>Users must be at least 18 years old or of legal age in their jurisdiction.</li>
            <li>Users confirm online betting is legal in their region.</li>
            <li>Only one account per user is allowed.</li>
            <li>Users must provide accurate registration details.</li>
            <li>SSBook reserves the right to verify user identity (KYC).</li>
          </ul>
  
          <h2 className="text-lg font-bold mt-6">3. Account Security & Responsibilities</h2>
          <ul className="list-disc list-inside mt-2 text-sm leading-6">
            <li>Users must secure their account credentials.</li>
            <li>Unauthorized access must be reported immediately.</li>
            <li>SSBook is not liable for losses due to user negligence.</li>
          </ul>
  
          <h2 className="text-lg font-bold mt-6">4. Deposits, Withdrawals & Transactions</h2>
          <ul className="list-disc list-inside mt-2 text-sm leading-6">
            <li>Valid payment methods must be used for deposits.</li>
            <li>Withdrawals can only be processed to the same deposit account.</li>
            <li>Minimum and maximum limits apply.</li>
            <li>Withdrawals are subject to verification and may take 24-72 hours.</li>
          </ul>
  
          <h2 className="text-lg font-bold mt-6">5. Betting Rules & Market Conditions</h2>
          <ul className="list-disc list-inside mt-2 text-sm leading-6">
            <li>Users must have sufficient funds before placing a bet.</li>
            <li>Once placed, bets cannot be canceled.</li>
            <li>SSBook reserves the right to adjust odds or void bets.</li>
          </ul>
  
          <h2 className="text-lg font-bold mt-6">6. Bonuses & Promotions</h2>
          <ul className="list-disc list-inside mt-2 text-sm leading-6">
            <li>Bonuses are subject to wagering requirements.</li>
            <li>Users attempting to exploit bonuses may be penalized.</li>
          </ul>
  
          <h2 className="text-lg font-bold mt-6">7. Responsible Gaming & Fair Play</h2>
          <ul className="list-disc list-inside mt-2 text-sm leading-6">
            <li>SSBook provides responsible gaming tools.</li>
            <li>Fair play policies prohibit match-fixing and bots.</li>
          </ul>
  
          <h2 className="text-lg font-bold mt-6">8. Fraud Prevention & Security</h2>
          <ul className="list-disc list-inside mt-2 text-sm leading-6">
            <li>SSBook employs advanced security measures.</li>
            <li>Fraudulent activities will lead to legal actions.</li>
          </ul>
  
          <h2 className="text-lg font-bold mt-6">9. Privacy & Data Protection</h2>
          <ul className="list-disc list-inside mt-2 text-sm leading-6">
            <li>SSBook complies with data protection laws.</li>
            <li>User data is never shared without consent.</li>
          </ul>
  
          <h2 className="text-lg font-bold mt-6">10. Account Suspension & Termination</h2>
          <ul className="list-disc list-inside mt-2 text-sm leading-6">
            <li>SSBook reserves the right to suspend accounts violating rules.</li>
            <li>Fraudulent funds will be confiscated.</li>
          </ul>
  
          <h2 className="text-lg font-bold mt-6">11. Dispute Resolution & Customer Support</h2>
          <ul className="list-disc list-inside mt-2 text-sm leading-6">
            <li>Disputes must be reported within 7 days.</li>
            <li>24/7 customer support is available.</li>
          </ul>
  
          <h2 className="text-lg font-bold mt-6">12. Legal & Jurisdiction</h2>
          <ul className="list-disc list-inside mt-2 text-sm leading-6">
            <li>These terms are governed by Curaçao law.</li>
            <li>Users are responsible for complying with local laws.</li>
          </ul>
  
          <h2 className="text-lg font-bold mt-6">13. Force Majeure</h2>
          <ul className="list-disc list-inside mt-2 text-sm leading-6">
            <li>SSBook is not responsible for disruptions beyond its control.</li>
          </ul>
  
          <h2 className="text-lg font-bold mt-6">14. Agreement & Acknowledgment</h2>
          <p className="mt-2 text-sm leading-6">
          🚀 By creating an account, you confirm that you have read, understood, and agreed to
            these Terms & Conditions.🚀
          </p>
              </div>
        </div>
      </div>
    );
  }
  