import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  FaRegUser,
  FaBars,
  FaTimes,
  FaPhone,
  FaWhatsapp,
} from "react-icons/fa";
import { FaSquarePlus } from "react-icons/fa6";
import { useAuth } from "../../Services/AuthContext";
import { MdLogout } from "react-icons/md";
import axios from "axios";
import { BASE_URL } from "../../Services/config";
function Navbar({ setIsSidebarOpen, isSidebarOpen }) {
  const logo = "/assets/logo/logobg.svg";
  const rupe = "/assets/images/rupe.png";
  const withdrawalImage = "/assets/images/widthdraw.png"

  const depositImage = "/assets/images/deposits.png";
  const AddImage = "/assets/images/add.svg";
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { user, logout, fetchUserProfile, token } = useAuth();
  const navigate = useNavigate();
  const [setting, setSetting] = useState([]);
  const mdCall = "/assets/images/wp.png"
  const whatsApp = `https://ssbook2.s3.ap-south-1.amazonaws.com/uploads/24*7chat.PNG`

  const handleRefresh = () => {
    if (token) {
      fetchUserProfile();
    }
  }

  const settingList = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/order/get-setting`, {
        withCredentials: true,
        headers: { "Content-Type": "application/json" },
      });

      const setting =
        response.data.result?.flatMap((item) => item.settingAll) || [];

      setSetting(setting);
    } catch (err) {
      console.error("Error fetching orders:", err);
    }
  };

  useEffect(() => {
    settingList();

  }, []);
  const number = setting.map((item) => item.wa_mobile);


  return (
    <nav className="w-screen">
      <div className="w-full h-[70px] bg-mainColor lg:border-b-2 md:border-b-2 md:border-richblue-100  lg:border-richblue-100 flex items-center justify-between px-5 md:px-10">
        <div className="flex items-center  space-x-3">
          <button
            onClick={() => setIsSidebarOpen(true)}
            className="text-white text-2xl"
          >
            {!isSidebarOpen && <FaBars />}
          </button>
          <Link onClick={handleRefresh}
            to="/HomeAfterLogin">
            <div className="mt-3" >
              <img src={logo} alt="logo" width={100} />
            </div>{" "}
          </Link>

          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="text-white text-2xl md:hidden"
          >
            {isMobileMenuOpen ? <FaTimes /> : null}
          </button>
        </div>

        <div className="hidden md:flex space-x-4">
          <Link
            onClick={handleRefresh}
            to="/Withdrawal"
            className="rounded-md font-bold w-32 h-10 flex items-center justify-center p-1 bg-[#B40100] text-white"
          >
            <img src={withdrawalImage} alt="Withdrawal" className="w-5 h-5" />
            <span className="ml-1">Withdrawal</span>
          </Link>
          <Link
            onClick={handleRefresh}

            to="/Deposites"
            className="rounded-md font-bold w-32 h-10 flex items-center justify-center p-1 bg-[#098509] text-white"
          >
            <img src={depositImage} alt="Deposit" className="w-5 h-5" />
            <span className="ml-1 font-bold">Deposit</span>
          </Link>
          <Link onClick={handleRefresh}
            to="/CreateNewId" className="mobile-btn">
           <div className="flex flex-row items-center justify-center gap-x-2 p-2 px-6 font-bold rounded-md bg-[#0B686E] text-white border border-black">
              <FaSquarePlus />  
         <p className="text-white font-Poppins text-md">Create Id</p>
           </div>


          </Link>

        </div>

        <div className="flex md:hidden space-x-4">
          <Link onClick={handleRefresh}
            to="/CreateNewId" className="mobile-btn">
           <div className="flex flex-row items-center justify-center gap-x-2 p-2 font-bold rounded-md bg-CreateIdBg text-white border border-black">
              <FaSquarePlus />  
         <p className="text-white font-Poppins text-md">CREATE ID</p>
           </div>


          </Link>
        </div>

        <div className="fixed bottom-6 right-6 flex items-center">
          <a
            href={`https://wa.me/${number}`}
            target="_blank"
            rel="noopener noreferrer"
            className=""
          >

            <img src={whatsApp} width={130} />



          </a>
        </div>
        <div className="flex items-center space-x-4">

          {user ? (

            <button
              onClick={() => {
                logout();
                navigate("/LoginPage");
              }}
              className="lg:rounded-3xl uppercase font-bold lg:w-28 h-10 flex items-center justify-center p-1 lg:bg-black text-white"
            >

              <span className="hidden md:block"> Logout</span>
              <span className="ml-2 font-bold">
                {" "}
                <MdLogout />{" "}
              </span>
            </button>
          ) : (
            <Link
              to="/LoginPage"
              className="lg:rounded-3xl uppercase font-bold lg:w-28 h-10 flex items-center justify-center p-1 lg:bg-black text-white"
            >
              <span className="hidden md:block">Login </span>
              <FaRegUser className="ml-1 md:hidden text-xl" />
            </Link>
          )}


        </div>
      </div>


      <div className="fixed  w-full bg-black flex md:hidden border-b-2 font-poppins border-richblue-100 justify-around py-2 shadow-lg gap-1 px-1">
      <Link to="/Deposites" onClick={handleRefresh}
          className="flex flex-row gap-x-3 items-center justify-center text-white m-1 text-sm bg-newDiposite w-full rounded-md p-1  border-[1px] border-white transition-all duration-300">
          <img src={depositImage} alt="Deposit" className="w-7 h-7" />
          <span className="text-md font-bold">DEPOSIT</span>
        </Link>
        <Link onClick={handleRefresh}
          to="/Withdrawal" className="flex flex-row gap-x-3 items-center justify-center p-1 m-1 bg-newWithdwal text-white text-sm  w-full rounded-md  border-[1px] border-white">
          <img src={withdrawalImage} alt="Withdrawal" className="w-7 h-7" />
          <span className="text-md font-bold">WITHDRAWAL </span>
        </Link>
        
      </div>

      {isMobileMenuOpen && (
        <div className="bg-mainColor w-full flex flex-col items-center space-y-4 py-4">
          <Link
            to="/CreateNewId"
            className="rounded-3xl w-48 h-12 font-bold flex items-center justify-center p-1 bg-buttonclr text-withdrawalbg"
          >
            <img src={AddImage} alt="Create New Id" className="w-6 h-6" />
            <span className="ml-2">Create New Id</span>
          </Link>

        </div>



      )}


    </nav>
  );
}

export default Navbar;
