import React, { useEffect, useRef, useState } from 'react';
import { FaPlus, FaSyncAlt, FaWhatsapp } from 'react-icons/fa';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FaArrowLeftLong, FaArrowRightLong } from 'react-icons/fa6';
import WithDrawTimer from '../../Pop-Up/WithDrawTimer';
import { ImCross } from 'react-icons/im';
import { useAuth } from '../../../Services/AuthContext';
import { toast } from 'react-toastify';
import { BASE_URL } from '../../../Services/config';
import { formatDateTime } from '../../../Services/config';
import axios from 'axios';
function Withdrawal() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [error, setError] = useState("");
  const [popupOpen, setPopupOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false)
  const [accountListTable, setAccountListTable] = useState([])
  const [selectedAccountDetails, setSelectedAccountDetails] = useState([]);
  const [amount, setAmount] = useState("");
  const [widrawListAll, setWidrawListAll] = useState([])
  const [setting, setSetting] = useState([])
  const [totalPages, setTotalPages] = useState("")
  const [selectedBank, setSelectedBank] = useState('');
  const [successMessage, setSuccessMessage] = useState("")
  const [gameList, setGameList] = useState([])
  const [selectedId1, setSelectedId1] = useState("");

  const sliderRef = useRef(null);

  const { user, token, fetchUserProfile } = useAuth();



  const formik = useFormik({
    initialValues: {
      accountType: '',
      name: '',
      accountNumber: '',
      ifsc: '',
    },
    validationSchema: Yup.object({
      accountType: Yup.string().required('Bank Name is required'),
      name: Yup.string().required('Name is required'),
      accountNumber: Yup.string()
        .matches(/^\d+$/, 'Account Number must be a valid number')
        .required('Account Number is required'),
      ifsc: Yup.string().required('IFSC Code is required'),
    }),
    onSubmit: async (values) => {
      setIsSaving(true)

      try {
        const payload = {
          ...values,
          userId: user.id,
        };

        const response = await fetch(`${BASE_URL}/order/widraw-account-create`, {
          method: "POST",
          headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },

          body: JSON.stringify(payload),
        });

        const data = await response.json();

        if (response.ok) {
          toast.success("submitted successfully")
          setIsOpen(false);
          fetchData()
          formik.resetForm();
        } else {
          toast.error(data.message || "Something went wrong")
        }
      } catch (error) {
        toast.error("Something went wrong. Please try again.")

      }
      finally {
        setIsSaving(false);
      }
    }

  });


  const fetchData = async () => {
    try {
      const response = await fetch(`${BASE_URL}/order/widraw-account-list?userId=${user.id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        credentials: "include"
      });
      if (!response.ok) throw new Error("Failed to fetch data");

      const result = await response.json();

      const userApiData = result.result.flatMap((item) => item.accountList);

      setAccountListTable(userApiData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const fetchwidrawListAll = async () => {
    try {
      const response = await fetch(`${BASE_URL}/order/widraw-list?userId=${user.id}&page=${currentPage}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        credentials: "include"
      });
      if (!response.ok) throw new Error("Failed to fetch data");

      const result = await response.json();

      const userApiData = result.result.flatMap((item) => item.widrawListAll.withdrawals);
      setTotalPages(result.result.flatMap((item) => item.widrawListAll.totalPages));

      setWidrawListAll(userApiData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  useEffect(() => {
    fetchwidrawListAll(currentPage);
  }, [currentPage]);




  const handleBankChange = (event) => {
    const selectedAccountNumber = event.target.value;
    setSelectedBank(selectedAccountNumber);

    const selectedAccount = accountListTable.find(
      (account) => String(account.id) === selectedAccountNumber
    );

    setSelectedAccountDetails(selectedAccount);
  };



  const scrollLeft = () => {
    console.log('Scrolling Left');
    sliderRef.current.scrollBy({
      left: -300,
      behavior: 'smooth',
    });
  };

  const scrollRight = () => {
    console.log('Scrolling Right');
    sliderRef.current.scrollBy({
      left: 500,
      behavior: 'smooth',
    });
  };

  const handleClosePopup = () => {
    setIsOpen(false);
    formik.resetForm();
  };

  const handleWithdraw = async () => {
    if (!amount || isNaN(amount) || Number(amount) <= 0) {
      setError("Required field*");
      return;
    }
    setIsPopupOpen(true)
    setIsLoading(true)
    try {
      const payload = {
        widrawAccountId: Number(selectedBank),
        userId: user.id,
        amount: Number(amount),
        gameAccountId:Number(selectedId1)
        
      };

      const response = await fetch(`${BASE_URL}/order/widraw`, {
        method: "POST",
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },

        body: JSON.stringify(payload),
      });

      const data = await response.json();
      if (response.ok) {
        toast.success(data.message)
        setSuccessMessage(data.message);
        setPopupOpen(true)
        fetchwidrawListAll()
        setAmount("")
        setError("");
      } else {
        toast.error(data.message)

      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.")
    }
    finally {
      setIsLoading(false);
    }
  };


  const handleChange = (e) => {
    setAmount(e.target.value);

  };
  const itemsPerPage = 10;

  const startIndex = (currentPage - 1) * itemsPerPage;

  const settingList = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/order/get-setting`, {
        withCredentials: true,
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
      });

      const setting = response.data.result?.flatMap(item => item.settingAll) || [];


      setSetting(setting);
    } catch (err) {
      console.error("Error fetching orders:", err);
    }
  };


  useEffect(() => {
    settingList();
  }, []);
  const fetchGameList = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/auth/game-account-list?userId=${user.id}`, {
        withCredentials: true,
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
      });

      const portalNames = response.data.result?.flatMap(item =>
        item.accountListAll?.filter(game => game.status === "SUCCESS") || []
      ) || [];
      setGameList(portalNames);
    } catch (err) {
      console.error("Error fetching orders:", err);
    }
  };


  useEffect(() => {
    fetchGameList();
  }, []);
  const handleChangeOption = (event) => {
    setSelectedId1(event.target.value);
  };
  const number = setting.map((item) => item.wa_mobile);
  const wa_mobile_widraw = setting.map((item) => item.wa_mobile_widraw)



  return (
    <div className="flex justify-center bg-mainColor w-full h-full p-4">
      <div className="border border-pure-greys-300 p-4 rounded-xl w-full h-fit">


        <div className="flex flex-wrap justify-between  gap-2 lg:gap-0 mb-6">
          <div className="w-full lg:w-[57%]">
            {isOpen && (

              <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 p-4">
                <div className="bg-white rounded-lg w-full max-w-2xl m-5">
                  <div className="relative flex items-center text-lg font-bold  bg-timebg p-3">
                    <h2 className="absolute left-2/4 transform  -translate-x-1/2 text-white">Add  Bank Details</h2>
                    <div className="ml-auto text-white">
                      <ImCross onClick={handleClosePopup} className="cursor-pointer" />
                    </div>
                  </div>

                  <div className="bg-addbg  p-6 rounded-b-lg">
                    <form onSubmit={formik.handleSubmit} className="space-y-4">
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        <div>
                          <label htmlFor="accountType" className="block text-sm font-medium text-gray-700">
                            Bank Name:
                          </label>
                          <input
                            type="text"
                            id="name"
                            name="accountType"
                            placeholder="Enter Bank Name"
                            className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none sm:text-sm"
                            value={formik.values.accountType}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.accountType && formik.errors.accountType && (
                            <div className="text-red mt-1 text-xs">{formik.errors.accountType}</div>
                          )}
                        </div>
                        <div>
                          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                            A/C Holder Name:
                          </label>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Enter Name"
                            className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none sm:text-sm"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.name && formik.errors.name && (
                            <div className="text-red mt-1 text-xs">{formik.errors.name}</div>
                          )}
                        </div>
                        <div>
                          <label htmlFor="accountNumber" className="block text-sm font-medium text-gray-700">
                            A/C Number:
                          </label>
                          <input
                            type="text"
                            id="accountNumber"
                            name="accountNumber"
                            placeholder="Enter A/C Number"
                            className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none sm:text-sm"
                            value={formik.values.accountNumber}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.accountNumber && formik.errors.accountNumber && (
                            <div className="text-red mt-1 text-xs">{formik.errors.accountNumber}</div>
                          )}
                        </div>
                        <div>
                          <label htmlFor="ifsc" className="block text-sm font-medium text-gray-700">
                            IFSC Code:
                          </label>
                          <input
                            type="text"
                            id="ifsc"
                            name="ifsc"
                            placeholder="Enter IFSC Code"
                            className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none sm:text-sm"
                            value={formik.values.ifsc}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.ifsc && formik.errors.ifsc && (
                            <div className="text-red mt-1 text-xs">{formik.errors.ifsc}</div>
                          )}
                        </div>
                      </div>
                      <div className="flex justify-center items-center gap-4">
                        <button
                          type="submit"
                          className="bg-green text-white px-6 py-2 rounded-md hover:bg-green-600 focus:outline-none"
                          disabled={isSaving}
                        >
                          {isSaving ? 'Saving...' : 'Save'}
                        </button>

                        <button
                          type="button"
                          className="bg-red text-white px-6 py-2 rounded-md hover:bg-red-600 focus:outline-none"
                          onClick={() => {
                            formik.resetForm();
                            setIsOpen(false);
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}

            <div className="w-full  ">
              <div className="flex justify-between  text-withdrawalbg  text-lg  ">
                <span className='font-bold'> Withdraw </span>
                <button onClick={() => setIsOpen(true)} className="bg-blue-500 text-white px-4 py-2 rounded-md flex items-center text-sm  ">
                  <FaPlus /> <span className='pl-1'>Add  Bank Details</span>
                </button>

              </div>
              <div className="bg-topbg uppercase rounded-t-md font-bold flex flex-col sm:flex-row items-center p-2 gap-4 sm:gap-x-6 mt-1">
                <label htmlFor="accountType" className="text-sm text-white whitespace-nowrap">
                  Select Account:
                </label>
                <select
                  id="accountType"
                  className="block w-full sm:w-64 px-3 py-2 rounded-md shadow-sm focus:outline-none sm:text-sm"
                  value={selectedBank}
                  onChange={handleBankChange}
                >
                  <option value="">-- Your account list --</option>
                  {accountListTable?.map((account, index) => (
                    <option key={index} value={account.id}>
                      {account.accountNumber} || {account.accountType} || {account.name}
                    </option>
                  ))}
                </select>

                <label htmlFor="accountType" className="text-sm text-white whitespace-nowrap">
                  SelectId:
                </label>
                <select
                  value={selectedId1}
                  onChange={handleChangeOption}
                  className="block w-full sm:w-64 px-3 py-2 rounded-md shadow-sm focus:outline-none sm:text-sm"

                >
                  <option > Select ID
                  </option>

                  {gameList?.map((item) => (
                    <option key={item?.id} value={item?.id}>
                      {item?.username} || {item?.portalName}

                    </option>
                  ))}

                </select>

              </div>

              <div className="bg-addbg p-4 sm:p-6 rounded-b-lg">
                {selectedAccountDetails && Object.keys(selectedAccountDetails).length > 0 && (
                  <div className="pt-4">
                    <table className="w-full text-sm">
                      <tbody>
                        <tr>
                          <td className="p-2 font-bold">Account Type:</td>
                          <td className="p-2">{selectedAccountDetails.accountType}</td>
                        </tr>
                        <tr>
                          <td className="p-2 font-bold">A/C No.:</td>
                          <td className="p-2">{selectedAccountDetails.accountNumber}</td>
                        </tr>
                        <tr>
                          <td className="p-2 font-bold">User Name:</td>
                          <td className="p-2">{selectedAccountDetails.name}</td>
                        </tr>
                        <tr>
                          <td className="p-2 font-bold">IFSC Code:</td>
                          <td className="p-2">{selectedAccountDetails.ifsc}</td>
                        </tr>
                      </tbody>
                    </table>

                    <input
                      type="number"
                      className="w-full px-3 py-2 rounded-md shadow-sm focus:outline-none sm:text-sm mt-2"
                      placeholder="Enter withdrawal amount(INR)"
                      value={amount}
                      onChange={(e) => {
                        const value = e.target.value;
                        handleChange(e);

                      }} />
                    {!amount && <p className="text-red m-2 text-xs">{error}</p>}
                    {amount !== "" && amount <= 0 && (
                      <p className="text-red m-2 text-xs">Value must be greater than 0</p>
                    )}
                  </div>
                )}

                <div className="flex justify-center items-center pt-6 gap-4">
                  <button
                    className="bg-redbg text-white px-6 py-2 rounded-md hover:bg-red-600 focus:outline-none w-full sm:w-auto"
                    onClick={handleWithdraw}
                    disabled={isLoading}
                  >
                    {isLoading ? 'Processing...' : 'Withdrawal'}
                  </button>

                </div>
              </div>
            </div>

            <div className="bg-white mt-6 p-6 rounded-md">
              <h1 className="text-lg text-redbg">Note :</h1>
              <ol className="list-decimal space-y-2 p-3 text-red font-poppins">
                <li>
                  The bank accounts for withdrawal must have the same name as the user registration name.
                  <br />
                  निकासी के लिए बैंक खाते का नाम उपयोगकर्ता पंजीकरण नाम के समान होना चाहिए।
                </li>
                <li>
                  All withdrawals will be sent to the added bank account.
                  <br />
                  सभी निकासी जोड़े गए बैंक खाते में भेजी जाएंगी।
                </li>
                <li>
                  If you need to make any changes, you will need to contact our support team.
                  <br />
                  यदि आपको कोई बदलाव करने की आवश्यकता हो, तो कृपया हमारी सहायता टीम से संपर्क करें।
                </li>
              </ol>
            </div>

            <div
              className="font-sans bg-withdrawalbg uppercase w-full mt-6 p-3 space-x-6 rounded-md flex justify-center items-center cursor-pointer"
              onClick={() => {
                window.open(`https://wa.me/${wa_mobile_widraw}`, "_blank");
              }}
            >
              For payment-related issues, <span className="font-bold">click here.</span>
              <FaWhatsapp size={28} />
            </div>




          </div>

          <div className="w-full lg:w-[42%] ">
            <div className="w-full">

              <div
                className="bg-blackbackgound w-full h-full p-2 rounded-lg overflow-x-auto"
                style={{
                  scrollbarWidth: "none",
                  msOverflowStyle: "none",
                }}
              >
                <div className="flex justify-between m-3 text-white gap-x-2">
                  <div>Withdraw List</div>
                  <div className=' flex gap-2'>
                    <button onClick={scrollLeft}>
                      <FaArrowLeftLong />
                    </button>
                    <button onClick={scrollRight}>
                      <FaArrowRightLong />
                    </button>
                    <button
                      onClick={() => window.location.reload()}
                      className="bg-blue-500 text-white px-3 ml-2 py-1 rounded-md shadow-md hover:bg-blue-600 transition flex items-center gap-2"
                    >
                      <FaSyncAlt />

                      Refresh
                    </button>

                  </div>
                </div>
                <button onClick={scrollLeft} className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white p-2 rounded-full">

                </button>

                <button onClick={scrollRight} className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white p-2 rounded-full"></button>

                <div ref={sliderRef}
                  className=" w-full overflow-x-auto bg-blackbackgound rounded-b-lg" style={{
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                  }}>
                  <div className="min-w-max">
                    <div className="grid grid-cols-5 bg-topbg text-white font-bold px-4 py-2 rounded-lg text-xs gap-2">
                      <div className="text-center bg-withdrawalbg text-black font-poppins border px-3 py-1 rounded-3xl truncate">
                        S.NO.
                      </div>
                      <div className="text-center bg-withdrawalbg text-black font-poppins border px-3 py-1 rounded-3xl truncate">
                        Remark
                      </div>

                      <div className="text-center bg-withdrawalbg text-black font-poppins border px-3 py-1 rounded-3xl truncate">
                        Amount(INR)
                      </div>
                      <div className="text-center bg-withdrawalbg text-black font-poppins border px-3 py-1 rounded-3xl truncate">
                        Status
                      </div>
                      <div className="text-center bg-withdrawalbg text-black font-poppins border px-3 py-1 rounded-3xl truncate">
                        Created At
                      </div>
                    </div>


                    <div>
                      {widrawListAll && widrawListAll.length > 0 ? (
                        widrawListAll?.map((transaction, index) => (
                          <div
                            key={index}
                            className="grid grid-cols-5 text-white border-b border-gray-700 px-4 py-3 text-xs gap-2"
                          >
                            <div className="text-center truncate">{startIndex + index + 1}.</div>

                            <div className="text-center truncate">
                              {transaction.remark ?? "Not Available"}
                            </div>
                            <div className="text-center truncate">{transaction.amount || "N/A"}</div>
                            <div
                              className={`flex items-center justify-center gap-x-1 px-3 py-1 rounded-md truncate ${transaction.status === "APPROVED"
                                ? "bg-darkGreen"
                                : transaction.status === "REJECTED"
                                  ? "bg-redbg"
                                  : "bg-liteBlue"
                                }`}
                            >
                              {transaction.status}
                            </div>                          <div className="text-center truncate">{(formatDateTime(transaction.createdAt))}</div>

                          </div>
                        ))
                      ) : (
                        <p className="text-white justify-center flex text-center p-2">No data found</p>
                      )}
                    </div>
                  </div>
                </div>

              </div>
              {widrawListAll && widrawListAll.length > 0 ? (
                <div className="w-full flex justify-center mt-4">
                  <div className="flex items-center gap-3">
                    <button
                      onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                      disabled={currentPage === 1}
                      className={`w-10 h-10 flex items-center justify-center bg-gray-700 text-white rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-500"
                        }`}
                    >
                      <FaArrowLeftLong />
                    </button>

                    <span className="text-white text-sm">
                      Page {currentPage} of {totalPages}
                    </span>

                    <button
                      onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                      disabled={currentPage == totalPages}
                      className={`w-10 h-10 flex items-center justify-center bg-gray-700 text-white rounded ${currentPage == totalPages ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-500"
                        }`}
                    >
                      <FaArrowRightLong />
                    </button>
                  </div>
                </div>

              ) : null}
            </div>


          </div>

        </div>
        {/* <PopupWithBlur isPopupOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen} /> */}
        <WithDrawTimer isPopupOpen={popupOpen} setIsPopupOpen={setPopupOpen} successMessage={successMessage} setSuccessMessage={setSuccessMessage} />

      </div>
    </div>
  );
}

export default Withdrawal;
