import React, { useState } from 'react'
import LoginPage from "./Components/Pages/LoginPage"
import SignInPage from './Components/Pages/SignInPage'
import { BrowserRouter, Routes, Route, createBrowserRouter, RouterProvider } from "react-router-dom";
import SideBar from './Components/Pages/SideBar'
import NavBar from "./Components/Pages/Navbar"
import AddAccount from "./Components/dasboard/Home/AddAccount"
import CreateNewId from "./Components/dasboard/Home/CreateNewId"
import Upi from "./Components/dasboard/Home/upi"
import HomeAfterLogin from "./Components/dasboard/Home/HomeAfterLogin"
import HomeBeforeLogin from "./Components/dasboard/Home/HomeBeforeLogin"
import DepositeDetails from "./Components/dasboard/Home/DepositeDetails"
import Dashboard from './Components/dasboard/dasboard';
import Deposites from './Components/dasboard/Home/Deposits';
import Withdrawal from './Components/dasboard/Home/Withdrawal';
import { AuthProvider } from './Services/AuthContext';
import PrivateRoute from './Services/PrivateRoute';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; //
import "./App.css";
import EighteenPluxModal from './Components/Pop-Up/EighteenPluxModal';
import Policy from "../src/Components/aboutPages/PrivacyPolicy"
import About from "../src/Components/aboutPages/About"
import RulesAndRegulations from "../src/Components/aboutPages/RulesAndRegulations"
import TearmAndConditions from "../src/Components/aboutPages/Tearm&Conditions"
import KYC from './Components/aboutPages/KYC';
import ResponsibleGaming from "./Components/aboutPages/ResponsibleGaming"
import GameAdition from "./Components/aboutPages/GameAddi"
function App() {
  const [isOpen, setIsOpen] = useState(false);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Dashboard />,
      children: [
        {
          index: true,
          element: <HomeAfterLogin />
        },
        {
          path: "/HomeAfterLogin",
          element:<HomeAfterLogin /> 
        },
        {
          path: "/AddAccount",
          element: <AddAccount />,
        },
        {
          path: "/CreateNewId",
          element: <PrivateRoute><CreateNewId /></PrivateRoute>
        },
        {
          path: "/DepositeDetails",
          element: <PrivateRoute><DepositeDetails /></PrivateRoute>,
        },
        {
          path: "/Deposites",
          element: <PrivateRoute> <Deposites /></PrivateRoute>,
        },
        {
          path: "/Withdrawal",
          element: <PrivateRoute><Withdrawal /></PrivateRoute>,
        },
        {
          path: "/Upi",
          element: <PrivateRoute><Upi /> </PrivateRoute>,
        },
        {
          path: "/HomeBeforeLogin",
          element: <PrivateRoute><HomeBeforeLogin /> </PrivateRoute>,
        },

      ]
    },


    {
      path: "/SignInPage",
      element: <SignInPage />,
    },

    {
      path: "/LoginPage",
      element: <LoginPage />,
    },
    {
      path: "/PrivacyPolicy",
      element: <Policy />,
    },
    {
      path: "/RulesAndRegulations",
      element: <RulesAndRegulations />,
    },
    {
      path: "/GameAdition",
      element: <GameAdition />,
    },
    {
      path: "/TearmAndConditions",
      element: <TearmAndConditions />,
    },
    {
      path: "/KYC",
      element: <KYC />,
    },
    {
      path: "/ResponsibleGaming",
      element: <ResponsibleGaming />,
    },
    {
      path: "/About",
      element: <About />,
    }

  ])
  return (
    <>
            <EighteenPluxModal isOpen={isOpen} setIsOpen={setIsOpen} />

      <AuthProvider>
        <RouterProvider router={router} />

        <ToastContainer position="top-right" autoClose={3000} />
      </AuthProvider>   </>
  )
}

export default App
