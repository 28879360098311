import { socialIcons, paymentMethods, companyLinks, gameLinks } from "../Data/Data";

export default function Footer() {
  return (
    <>
      <footer className="bg-mainColor text-white py-10 px-5 md:px-20 hidden md:block">
        <div className="flex flex-col items-center justify-center md:text-center md:items-center">
          <p className="font-semibold text-md">
            Accept, process & disburse digital payments for your business.
          </p>
          <div className="flex flex-wrap justify-center gap-4 mt-4">
            {paymentMethods.map((item, index) => (
              <img
                key={index}
                src={item.src}
                alt={item.name}
                className="h-6 w-auto md:h-8"
              />
            ))}
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-between border-t border-pure-greys-300 pt-4 gap-x-4 mt-10">
          {/* Left Section */}
          <div className="md:w-1/3 text-center md:text-left">
            <h2 className="text-lg font-bold">Ssbook</h2>
            <p className="mt-2 text-sm md:text-md">
              We are a trusted and licensed Indian betting website that offers a
              wide range of sports betting options. Our user-friendly interface,
              competitive odds, and live betting and streaming features provide an
              immersive betting experience.
            </p>
            <div className="flex justify-center md:justify-start gap-3 mt-4">
              {socialIcons.map((item, index) => (
            <a key={index} href={item.url} target="_blank" rel="noopener noreferrer">
                <img
                  key={index}
                  src={item.src}
                  alt={item.name}
                  className="cursor-pointer w-8 h-8 transition-transform transform hover:-translate-y-1 hover:scale-110"
                />
               </a>
              ))}
            </div>
          </div>

          {/* Middle Section - Games */}
          <div className="md:w-1/3 mt-6 md:mt-0 text-center md:text-left">
            <h3 className="font-semibold text-lg">Game</h3>
            <ul className="text-sm mt-2 space-y-1 grid grid-cols-2 md:block gap-1">
              {gameLinks.map((game, index) => (
                <li key={index} className="text-center md:text-left">
                  <a href={game.href} className=" cursor-pointer">
                    {game.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* Right Section - Company */}
          <div className="md:w-1/3 mt-6 md:mt-0 text-center md:text-left">
            <h3 className="font-semibold text-lg">Company</h3>
            <ul className="text-sm mt-2 grid grid-cols-2 md:block gap-1">
              {companyLinks.map((link, index) => (
                <li key={index} className="text-center md:text-left">
                  <button
                    onClick={() =>
                      window.open(
                        link.url,
                        "_blank",
                        "noopener,noreferrer,width=600,height=600"
                      )
                    }
                    className=""
                  >
                    {link.name}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="mt-6 text-sm text-center border-t border-pure-greys-300 pt-4">
          &copy; 2025 Ssbook. All Rights Reserved.
        </div>
      </footer>

      <footer className="md:hidden">
        <div className="bg-mainColor text-white text-center p-6 border-t border-black">
          <p className="font-semibold text-sm ">
            Accept, process & disburse digital payments for your business.
          </p>
          <div className="flex flex-wrap justify-center p-x-1 gap-2 my-3">
            {paymentMethods.map((method) => (
              <img key={method.name} src={method.src} alt={method.name} className="h-6 w-14 overflow-clip " />
            ))}
          </div>
          <div className="flex  justify-center gap-3 border-t py-4 border-b border-pure-greys-300 my-4">
            {socialIcons.map((item, index) => (
                <a key={index} href={item.url} target="_blank" rel="noopener noreferrer">
              <img
                key={index}
                src={item.src}
                alt={item.name}
                className="cursor-pointer w-8 h-8 transition-transform transform hover:-translate-y-1 hover:scale-110"
              />
              </a>
            ))}
          </div>
          <div className="text-sm leading-6">
           <div className="flex  py-4 border-b border-pure-greys-300" >
           <p className="font-bold">Game:</p>
            <span>
              {gameLinks.map((game, index) => (
                <span key={index}>
                  <a href={game.href} className="text-pure-greys-100 hover:text-pure-greys-300 text-sm">
                    {game.name}
                  </a>
                  {index !== gameLinks.length - 1 && " | "}
                </span>
              ))}
            </span>
           </div>
           <div className="flex  py-4 border-b border-pure-greys-300">
           <span>Company:</span><span className="font-bold "> 
            { companyLinks.map((link, index) => (
                <span key={index}>
                  <button
                    onClick={() =>
                      window.open(
                        link.url,
                        "_blank",
                        "noopener,noreferrer,width=600,height=600"
                      )
                    }
                    className="text-pure-greys-100 font-normal text-sm hover:text-pure-greys-300 bg-transparent border-none cursor-pointer p-0"
                  >
                    {link.name}
                  </button>
                  {index !== companyLinks.length - 1 && " | "}
                </span>
              ))}
            </span>
        </div>
          </div>
          <p className="text-xs mt-4">© 2025 Ssbook. All Rights Reserved.</p>
        </div>
      </footer>
    </>
  );
}