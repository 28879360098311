import React, { useEffect, useRef, useState } from "react";
import { ImCross } from "react-icons/im";
import { LuAlarmClock } from "react-icons/lu";
import "../Spinner/Spinner.css";
import WithDrawPop from "./WithDrawPop";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../Services/config";
const DepositTimer = ({ isPopupOpen, setIsPopupOpen, successMessage }) => {
  const [openDepositPopUp, setOpenDepositPopUp] = useState(false);
  const [setting, setSetting] = useState([]);
  const [timeLeft, setTimeLeft] = useState(null);
  const navigate = useNavigate()
  const hasToastFired = useRef(false);
  const settingList = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/order/get-setting`, {
        withCredentials: true,
        headers: { "Content-Type": "application/json" },
      });

      const settingData = response.data.result?.flatMap((item) => item.settingAll) || [];
      setSetting(settingData);
    } catch (err) {
      console.error("Error fetching settings:", err);
    }
  };

  useEffect(() => {
    settingList();
  }, []);

  useEffect(() => {
    if (isPopupOpen && setting.length > 0) {
      const deposit_timer = setting[0]?.deposit_timer;

      if (deposit_timer) {
        const totalSeconds = deposit_timer * 60;
        setTimeLeft(totalSeconds);
        hasToastFired.current = false;
        const timer = setInterval(() => {
          setTimeLeft((prev) => {
            if (prev <= 1) {
              clearInterval(timer);
              setIsPopupOpen(false);
              navigate("/Deposites")
              if (!hasToastFired.current) {
                // toast.success(successMessage);
                hasToastFired.current = true;
              }
              return null;
            }
            return prev - 1;
          });
        }, 1000);

        return () => clearInterval(timer);
      }
    }
  }, [isPopupOpen, setting]);

  const handeClose = () => {
    setIsPopupOpen(false)
    navigate("/Deposites")

  }

  return (
    <div className="max-w-[500px]">
      {isPopupOpen && timeLeft !== null && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-sm"></div>
          <div className="relative bg-white w-120 rounded-md shadow-lg">
            <div className="flex text-lg font-bold text-center text-white bg-timebg justify-center p-2 gap-x-2">
              <h2>Timer</h2>
              <LuAlarmClock size={28} />
              <div className="absolute right-2 top-2 cursor-pointer">
                <ImCross onClick={handeClose} />
              </div>
            </div>
            <ol className=" list-decimal text-sm text-red font-poppins space-y-2 p-5">
              <li>
                Deposit money only in the below available accounts to get the fastest credits and avoid possible delays.
                <br /> केवल नीचे दिए गए उपलब्ध खातों में ही पैसे जमा करें ताकि आपको सबसे तेज़ क्रेडिट मिले और संभावित देरी से बचा जा सके।
              </li>
              <li>
                Deposit mode after the account removal from the site will be responsible by customer only.
                <br /> खाता साइट से हटाए जाने के बाद जमा करने का तरीका ग्राहक की जिम्मेदारी होगी।
              </li>
              <li>
                After deposit, add your UTR and amount to receive balance.
                <br /> जमा करने के बाद, अपना UTR और राशि जोड़ें ताकि बैलेंस प्राप्त किया जा सके।
              </li>
            </ol>

            <div className="text-center mt-4">
              <button className="bg-caribbeangreen-200 text-white px-8 mb-4 py-2 rounded-md">
                {Math.floor(timeLeft / 60)} Min : {timeLeft % 60} Sec
              </button>
            </div>

            <div className="flex justify-center items-center mt-4 mb-8">
              <div className="spinner"></div>
            </div>
          </div>
        </div>
      )}
      <WithDrawPop isPopupOpen={openDepositPopUp} setIsPopupOpen={setOpenDepositPopUp} />
    </div>
  );
};

export default DepositTimer;
