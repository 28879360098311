export default function RulesAndRegulations() {
  const logo = "/assets/logo/logobg.svg";
    return (
      <div className="bg-mainColor min-h-screen flex justify-center">
        <div className="bg-gray-900 text-white rounded-lg shadow-lg">
          {/* Header Section */}
          <div className="bg-mainColor w-full py-2 shadow-md flex flex-col justify-start fixed">
          <div className="flex items-center gap-2 text-3xl font-bold">
          <img src={logo} alt="Company Logo" />
          </div>
  
          {/* Rules & Regulations Header */}
          <div className="bg-black text-white text-lg font-bold p-2 mt-2">
            Rules & Regulations
          </div>
  </div>
         <div className="mt-36 p-4 bg-richblack-700">
           {/* Rules & Regulations Content */}
           <h2 className="text-lg font-bold mt-2 ">1. Introduction</h2>
          <p className="mt-2 text-sm leading-6">
            Welcome to <strong>SSBook - Saawaliya Syndicate</strong>, a fully licensed and regulated online gaming 
            and betting platform under the <strong>Curaçao eGaming Authority (License No. 115/JAZ)</strong>.
          </p>
  
          <h2 className="text-lg font-bold mt-6">2. Account Registration & User Eligibility</h2>
          <ul className="list-disc list-inside mt-2 text-sm leading-6">
            <li><strong>Age Restriction:</strong> Must be 18+ or legal age in your jurisdiction.</li>
            <li><strong>Single Account Policy:</strong> Multiple accounts will result in suspension.</li>
            <li><strong>Identity Verification:</strong> Government-issued ID, proof of address, and payment verification required.</li>
            <li><strong>Restricted Regions:</strong> Users from restricted countries cannot register.</li>
            <li><strong>Account Security:</strong> Users are responsible for maintaining account security.</li>
          </ul>
  
          <h2 className="text-lg font-bold mt-6">3. Betting Rules & Market Regulations</h2>
          <ul className="list-disc list-inside mt-2 text-sm leading-6">
            <li><strong>Placing Bets:</strong> Once confirmed, bets cannot be canceled or modified.</li>
            <li><strong>Market & Odds Changes:</strong> SSBook can adjust or suspend betting markets anytime.</li>
            <li><strong>Fair Play Policy:</strong> No match-fixing, insider betting, or automated bots.</li>
            <li><strong>Settlements & Payouts:</strong> Bets are settled based on official event results.</li>
          </ul>
  
          <h2 className="text-lg font-bold mt-6">4. Deposits, Withdrawals & Transactions</h2>
          <ul className="list-disc list-inside mt-2 text-sm leading-6">
            <li><strong>Deposit Rules:</strong> Deposits must be made using valid payment methods.</li>
            <li><strong>Withdrawal Policy:</strong> Withdrawals processed within 24-72 hours.</li>
            <li><strong>Transaction Security:</strong> Secure encryption technology ensures financial safety.</li>
          </ul>
  
          <h2 className="text-lg font-bold mt-6">5. Bonuses & Promotions</h2>
          <ul className="list-disc list-inside mt-2 text-sm leading-6">
            <li><strong>General Bonus Terms:</strong> Bonuses come with specific wagering requirements.</li>
            <li><strong>Bonus Abuse Prevention:</strong> Exploiting bonus systems leads to revocation.</li>
          </ul>
  
          <h2 className="text-lg font-bold mt-6">6. Responsible Gaming & Player Protection</h2>
          <ul className="list-disc list-inside mt-2 text-sm leading-6">
            <li><strong>Commitment to Responsible Gaming:</strong> Users can set limits or self-exclude.</li>
            <li><strong>Age Verification:</strong> Underage accounts will be permanently banned.</li>
          </ul>
  
          <h2 className="text-lg font-bold mt-6">7. Security, Fraud Prevention & Compliance</h2>
          <ul className="list-disc list-inside mt-2 text-sm leading-6">
            <li><strong>Data Protection:</strong> User data is securely stored and never shared without consent.</li>
            <li><strong>Anti-Fraud Measures:</strong> Fraudulent activities will be reported to authorities.</li>
          </ul>
  
          <h2 className="text-lg font-bold mt-6">8. Account Suspension & Termination</h2>
          <ul className="list-disc list-inside mt-2 text-sm leading-6">
            <li><strong>Grounds for Suspension:</strong> Fraud, multiple accounts, or security violations.</li>
            <li><strong>Termination:</strong> Serious offenses lead to permanent account closure.</li>
          </ul>
  
          <h2 className="text-lg font-bold mt-6">9. Dispute Resolution & Customer Support</h2>
          <ul className="list-disc list-inside mt-2 text-sm leading-6">
            <li><strong>Dispute Handling:</strong> Contact SSBook support for resolution.</li>
            <li><strong>24/7 Customer Support:</strong> Available via live chat, email, and phone.</li>
          </ul>
  
          <h2 className="text-lg font-bold mt-6">10. Legal Disclaimer</h2>
          <ul className="list-disc list-inside mt-2 text-sm leading-6">
            <li><strong>Jurisdiction Responsibility:</strong> Users must ensure online gaming is legal in their region.</li>
            <li><strong>Force Majeure:</strong> SSBook is not responsible for service interruptions due to unforeseen circumstances.</li>
          </ul>
  
          <p className="mt-6 text-sm font-bold">
            By using SSBook - Saawaliya Syndicate, you confirm that you have read, understood, and agreed to these Rules & Regulations.
          </p>
         </div>
        </div>
      </div>
    );
  }
  