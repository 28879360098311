import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import axios from "axios";
import { Link } from "react-router-dom";
import Game from "../../Game/Game";
import { BASE_URL } from "../../../Services/config";
import { useAuth } from "../../../Services/AuthContext";
import EighteenPluxModal from "../../Pop-Up/EighteenPluxModal";
import Footer from "../../Pages/Footer"
import { images } from "../../Data/Data";
function Home2() {
  const sliderRef = useRef(null);
  const [category, setCategories] = useState([]);
  const [setting, setSetting] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const { token } = useAuth();
  const [banner, setbanner] = useState([])

  const fetchOrders = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/order/portal-list`, {

        withCredentials: true,
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
      });
      const portalNames =
        response.data.result?.flatMap((item) => item.portalList) || [];

      console.log(portalNames);
      setCategories(portalNames);
    } catch (err) {
      console.error("Error fetching orders:", err);
    }
  };

  const fetchBanner = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/order/get-home-banner`, {

        withCredentials: true,
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
      });
      const banner =
        response.data.result?.flatMap((item) => item.banner) || [];
      setbanner(banner);
    } catch (err) {
      console.error("Error fetching orders:", err);
    }
  };

  useEffect(() => {
    fetchOrders();
    fetchBanner();
  }, []);

  const mainBanners = banner.filter(b => b.type === "MAIN");
  const aviatorBanners = banner.filter(b => b.type === "AVIATOR")
  const livePortal = category.filter(b => b.live === "YES")


  const settings = {
    dots: false,
    infinite: mainBanners.length > 1,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: mainBanners.length > 1,
    autoplaySpeed: 3000,
    arrows: mainBanners.length > 1,
  };
  const handleCardClick = (index, iteam) => {
    setSelectedCard(index);
  };

  const settingList = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/order/get-setting`, {
        withCredentials: true,
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
      });
      const setting =
        response.data.result?.flatMap((item) => item.settingAll) || [];

      setSetting(setting);
    } catch (err) {
      console.error("Error fetching orders:", err);
    }
  };

  useEffect(() => {
    settingList();
  }, []);
  const image = setting.map((item) => item.s3_img);
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div
      style={{
        backgroundImage: `url('/assets/images/cricket.svg'), linear-gradient(180deg, rgba(0, 0, 0, 0) 69%, #000000 93.5%)`,
        backgroundAttachment: "fixed",
      }}
      className="bg-black w-full mx-auto h-screen  mt-2  "
    >
      <div className="p-2 m-3 border rounded-lg  border-pure-greys-300  ">

        <div className="w-full mx-auto overflow-hidden md:hidden">
          <Slider {...settings} className="w-full">
            {mainBanners.map((src, index) => (
                                            <Link to={src.link || "#"} target="_blank" key={index}>
              <div key={index} className="w-full">
                <img
                  src={`${image}${src.img_mbl}`}
                  alt={`Slide ${index + 1}`}
                  className="w-full h-auto object-contain border rounded-xl"
                />
              </div>
            </Link>
            ))}
          </Slider>
        </div>



        <div className="w-full mx-auto h-[400px] overflow-hidden sm:h-[250px]  md:h-[300px] lg:h-[400px] hidden md:flex ">
          <Slider {...settings} className="w-full h-[400px]">
            {mainBanners.map((src, index) => (
                              <Link to={src.link || "#"} target="_blank" key={index}>

              <div key={index} className="w-full h-[400px]">
                <img
                  src={`${image}${src.img}`}
                  alt={`Slide ${index + 1}`}
                  className="w-full  h-[400px] object-fill border rounded-xl    sm:h-[250px] md:h-[300px] lg:h-[400px]  "
                />
              </div>
            </Link>
            ))}
          </Slider>
        </div>


        <div className=" w-full  mx-auto ">
          <div className="flex justify-between ">


          </div>

          <div className="relative w-full">
            <div
              ref={sliderRef}
              className="flex flex-col md:flex-row gap-2 overflow-x-auto whitespace-nowrap scroll-smooth px-2 no-scrollbar w-full items-center mt-2"
              style={{
                scrollbarWidth: "none",
                msOverflowStyle: "none",
              }}
            >

            </div>
          </div>

        </div>

        <div className="w-full h-auto rounded-lg mt-2 ">
          <Slider {...sliderSettings}>
            {aviatorBanners.map((i, index) => (
              <Link to={i.link || "#"} target="_blank" key={index} >

                <div key={index} className="p-2 ">
                  <img
                    loading="lazy"
                    src={`${image}${i.img}`}
                    className="rounded-lg border-[#6B9EA1] border object-fill w-[150px] sm:h-[100px] lg:h-auto md:w-full"
                    alt={`banner-${index}`}
                  />
                </div>
              </Link>
            ))}
          </Slider>
        </div>
        <div className="my-4 w-full  mx-auto justify-center align-item  bg-backgrounds  rounded-lg flex flex-col items-center p-4 bg-availblebgs ">
          <div className="flex justify-center items-center text-center w-full">
            <div className="font-poppins text-xl text-white font-bold pt-2">
              Available Sites
            </div>
          </div>


          <div className="relative w-full mt-3">

            <div className="grid grid-cols-3 sm:gap-2 md:gap-2 lg:gap-4 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-6 gap-3">
              {livePortal.map((item, index) => (
                <Link to={item.redirectUrl || "#"} target="_blank" key={index}>
                  <div
                    onClick={() => handleCardClick(index, item)}
                    className={`w-full xs:w-[100px] sm:w-[140px] md:w-[200px] lg:w-[220px] h-auto rounded-lg shadow-lg  
        bg-abailblebg text-white p-2 border ${selectedCard === index ? "border-borderColor" : "border-borderColor"}`}
                  >
                    <div className="flex justify-center items-center mb-2  sm:h-auto max-h-[150px] ">
                      <img src={`${image}${item.img}`} className="w-full max-h-[150px]  object-fill" />
                    </div>

                    <div className="relative group text-center">
                      <button className="bg-CreateIdBg w-full h-[35px] rounded-lg text-white font-bold font-poppins text-xs sm:text-sm">
                        <span className="text-[10px] p-1 md:text-[16px] font-semibold text-center block w-full truncate">
                          {item.name}
                        </span>
                      </button>
                      <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 w-max bg-gray-700 text-white text-xs rounded-lg px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity">
                        {item.redirectUrl}
                      </span>
                    </div>
                  </div>
                </Link>
              ))}
            </div>


          </div>

        </div>
        <div className="mt-0">
          <div className="text-white justify-self-end p-2 flex gap-x-4 flex-wrap">

          </div>


          <Game />
          <Footer />
          <EighteenPluxModal
            isOpen={isModalOpen}
            setIsOpen={setIsModalOpen}
            onClose={() => setIsModalOpen(false)}
          >
          </EighteenPluxModal>


        </div>
      </div>
    </div>
  );
}

export default Home2;
